import mainTheme from './main';

const main = '#42b048';
const dark = "#1e6b28";
const light = "#2e8d32";

export default {
  ...mainTheme,
  primary: {
    dark: dark,
    main: main,
    light: light
  },
};

