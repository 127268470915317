import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import t from '../../../../../configs/translations';
import { downloadInvoiceAction } from '../../../../../actions/Account';
import Helper from '../../../../../common/helper';
import {
  Grid,
  Paper,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    margin: "1% 0",
    padding: "1% 4%",
    borderRadius: 0
  }
}));


const DownloadInvoice = props => {
  const { className, total, invoicer, ...rest } = props;
  const authData = useSelector(state => state.authData);
  const accountId = invoicer || authData.accountId;
  const selectedMonth = useSelector(state => state.month);
  const classes = useStyles();
  const theme = useTheme();

  const downloadInvoice = async () => {
    const params = Helper.putDateParams(selectedMonth);
    const response = await downloadInvoiceAction({
      ...params,
      invoicerAccountId: accountId,
      total: JSON.stringify(total),
      token: authData.token
    });
    const fileURL = window.URL.createObjectURL(new Blob([response.data]));
    const fileLink = document.createElement('a');
    fileLink.href = fileURL;
    fileLink.setAttribute('download', 'Invoice.pdf');
    document.body.appendChild(fileLink);

    fileLink.click();
  };

  return (
    <Paper className={classes.root}>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={2}
        onClick={()=>downloadInvoice()}
      >
        <Grid item xs={10}>
          <Typography variant="h5">{t.downloadInvoice}</Typography>
        </Grid>
        <Grid item xs={2}>
          <img alt="shopIcon" src="images/downloadInvoice.png" />
        </Grid>
      </Grid>
    </Paper>

  );
};

DownloadInvoice.propTypes = {
  className: PropTypes.string
};

export default DownloadInvoice;
