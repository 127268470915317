import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import PersonIcon from '@material-ui/icons/Person';
import { userLogoutAction } from "../../../../actions/Account";
import { Sections, MonthPicker, BackButton } from './components';
import ENUMS from '../../../../configs/enums';
import t from '../../../../configs/translations'
import { useLocation } from 'react-router-dom'

import {
  Link,
  Typography,
  AppBar,
  Toolbar,
  Badge,
  Hidden,
  IconButton,
  Paper,
  Tab,
  Tabs, Grid
} from '@material-ui/core';

const pagesWithoutMonthPicker = [`network`, `settings`, `account`];
const pagesWithBackButton = [`dashboard`, 'commission', 'bonus']

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    backgroundColor: theme.palette.background.default,
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  topLogo: {
    // color: "#fff"
  },
  logoImage: {
    height: '50px',
    maxWidth: 295
  },
  underliner: {
    backgroundColor: theme.palette.primary.main,
    height: '2px',
  },
  personIcon: {
    transform: 'scale(1.7)'
  },
  tab: {
    textTransform: 'capitalize',
    fontSize: 16,
    minHeight: 40,
    background: `linear-gradient(135deg, ${theme.palette.text.main} 59%, ${theme.palette.divider} 95%)`,
  },
  shopIcon: {
    fill: theme.palette.primary.main,
    height: '45px',
    width: '45px',
    right: 0,
    position: 'absolute',
    top: -15
  },
  tabs: {
    minHeight: 40,
    color: theme.palette.white,
    '& .Mui-selected': {
      background: `linear-gradient(135deg, ${theme.palette.primary.main} 60%, ${theme.palette.primary.light} 98%)`,
    }
  },
  buttonText: {
    color: theme.palette.primary.main,
    fontSize: 12,
    position: 'absolute',
    right: 9,
    top: 25
  }
}));

const Topbar = props => {
  let location = useLocation();
  const { className, onSidebarOpen, ...rest } = props;
  const classes = useStyles();
  const roleId = useSelector(state => state.authData.subTypeId);
  const isHideMonthPicker = pagesWithoutMonthPicker.includes(location.pathname.split("/")[1]);
  const selectedAccount = useSelector(state => state.selectedAccount);
  const logoImg = useSelector(state => state.skin.logo);
  const [notifications] = useState([]);
  const dispatchXX = useDispatch();
  const showTabs = !!selectedAccount.id ? true : (roleId !== ENUMS.roleIds.shop && roleId !== ENUMS.roleIds.distributor && roleId !== ENUMS.roleIds.consultant);
  const isShowBackButton = !!selectedAccount.id && pagesWithBackButton.includes(location.pathname.split("/")[1]);

  const logoutUser = () => {
    userLogoutAction(dispatchXX);
  }

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar>
        <RouterLink to="/">
          <Typography className={classes.topLogo}
          >
            <img
              alt="logo"
              className={classes.logoImage}
              src={`images/logos/${logoImg}`}
            />
          </Typography>
          {/*<img className={classes.topLogo}*/}
          {/*  alt="Logo"*/}
          {/*  src="/images/logos/passion.png"*/}
          {/*/>*/}
        </RouterLink>
       
          {/* <Hidden mdDown>
            <IconButton color="primary">
              <Badge
                badgeContent={notifications.length}
                color="primary"
                variant="dot"
              >
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Hidden> */}
        <div className={classes.flexGrow} />
        <Hidden lgUp>
          <IconButton
            color="primary"
            onClick={onSidebarOpen}
          >
            {roleId === ENUMS.roleIds.shop ?
              <div>
                <svg className={classes.shopIcon}>
                  <use xlinkHref="images/shopIcon.svg#Livello_1" />
                </svg>
                <span className={classes.buttonText}>{t.shop}</span>
              </div>
             :
              <PersonIcon  
                className={classes.personIcon}
              />
            }
          </IconButton>
        </Hidden>
      </Toolbar>

      {/* Show top Tabs*/}
      { showTabs && <Sections /> }
      { !isHideMonthPicker && <MonthPicker />}
      { isShowBackButton && <BackButton /> }
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
