import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import Hidden from '@material-ui/core/Hidden';
import StarIcon from '@material-ui/icons/Star';
import t from '../../../configs/translations';
import Helper from '../../../common/helper';

import {
  Card,
  Grid,
  CardContent,
  Avatar,
  Link,
  TableCell,
  Typography,
  LinearProgress,
  ListItem,
  List,
} from '@material-ui/core';

// import { getInitials } from 'helpers';
const avatarUrl = '/images/avatars/anonym.png';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    // minWidth: 1050
    '& .MuiGrid-item': {
      padding: 8
    }
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  accountType: {
    textTransform: 'capitalize'
  },
  bonusContainer: {
    display: 'inline-block',
    width: '30%',
    textAlign: 'right'
  },
  username: {
    position: 'absolute',
    fontSize: 12,
    marginTop: '-12px'
  },
  progressBarContainer: {
    display: 'inline-block',
    width: '70%'
  },
  progressBar: {
    display: 'inline-block',
    width: '50%',
    marginRight: 10
  },
  star: {
    color: `${theme.palette.warning.main}`,
    transform: 'scale(0.7)'
  },
  step: {
    color: theme.palette.primary.main,
    fontSize: 12,
    fontWeight: 500
  },
  fee: {
    color: theme.palette.primary.main,
  },
  bonus: {
    color: theme.palette.text.light
  },
  bordered: {
    borderRight: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  bottomBorder: {
    borderBottom: `1px solid ${theme.palette.divider}`
  }, 
  rightBorder: {
    borderRight: `1px solid ${theme.palette.divider}`
  },
  playersBonus: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    '& .MuiLinearProgress-colorPrimary': {
      backgroundColor: theme.palette.warning.lighter,
      height: 10
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: theme.palette.warning.main 
    }
  },
  depositeBonus: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    '& .MuiLinearProgress-colorPrimary': {
      backgroundColor: theme.palette.info.lighter ,
      height: 10
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: theme.palette.info.main 
    }
  }
}));

const getUserBonusesList = function(user, type) {
  let list = user.bonus.bonuses[type];
  if(list.length > 0) {
    if(list.objectives) {
      return list.objectives;
    } else {
      return list;
    }
  }

  return [];
}

const ChildrenTable = props => {
  const { className, accounts, roleType, rowsPerPage, page, pageType, ...rest } = props;
  const classes = useStyles();
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Grid container>
                {pageType === "bonus" &&
                  <React.Fragment>
                    <Hidden only={'xs'}>
                      <Grid className={classes.bottomBorder} item md={4} sm={4} lg={4} xs={4}>
                        <ListItem>
                          {t.shop}
                        </ListItem>
                      </Grid>
                      <Grid className={classes.bottomBorder} item md={4} sm={4} lg={4} xs={4}>
                        <ListItem>Players Bonus</ListItem>
                      </Grid>
                      <Grid className={classes.bottomBorder} item md={4} sm={4} lg={4} xs={4}>
                        <ListItem>Deposits Bonus</ListItem>
                      </Grid>
                  </Hidden>
                </React.Fragment>
                }
                {pageType === "earnings" &&
                <React.Fragment>
                  <Grid className={classes.bottomBorder} item md={4} sm={4} lg={4} xs={4}>
                    <ListItem>
                      {t.shop}
                      <StarIcon className={classes.star}/>
                    </ListItem>
                  </Grid>
                  <Grid className={classes.bottomBorder} item md={4} sm={4} lg={4} xs={4}>
                    <ListItem>{t.overall}</ListItem>
                  </Grid>
                  <Grid className={classes.bottomBorder} item md={4} sm={4} lg={4} xs={4}>
                    <ListItem className={classes.fee}>{t.fee}</ListItem>
                  </Grid>
                </React.Fragment>
                }
                  {accounts.map(user => (
                    <Grid key={user._id}  container>
                    {pageType === "bonus" &&
                      <React.Fragment>
                      <Grid className={classes.rightBorder} item md={4} sm={4} xs={4} lg={4}>
                        <Link
                          component={RouterLink}
                          to={`/account/view/${user._id}`}
                          variant="h6"
                        >
                          <div className={classes.nameContainer}>
                            <Hidden only={'xs'}>
                              <Avatar
                                className={classes.avatar}
                                src={avatarUrl}
                              >
                                {user.username}
                              </Avatar>
                              <Typography variant="body1">{user.username}</Typography>
                            </Hidden>
                          </div>
                        </Link>
                      </Grid>
                      <Grid className={classes.playersBonus} item md={4} lg={4} sm={4} xs={8}>
                        { getUserBonusesList(user, 'byPlayers')
                          .map(obj =>
                            {
                              return (obj.achievement < obj.goal  && obj.achievement !== 0 &&
                                (<div  key={obj.level}>
                                  <div className={classes.progressBarContainer}>
                                    <LinearProgress
                                      variant="determinate"
                                      className={classes.progressBar}
                                      value={obj.achievement / obj.goal * 100}
                                      /> 
                                      <span style={{fontSize: '11px'}}>{ obj.achievement}/{obj.goal}</span>
                                      <p style={{fontSize: '12px'}}>{t.bonusTypes.byPlayers} / <span className={classes.step}>{t.step} {obj.level}</span></p>
                                   </div>
                                   <div className={classes.bonusContainer}>
                                      <span style={{fontSize: '11px'}}>Bonus</span>
                                      <p className={classes.bonus}>
                                        { Helper.formatMoney(obj.bonus) }
                                      </p>
                                   </div>
                                </div>) 
                              )
                            }
                          )
                        }
                      </Grid>
                      <Hidden only={['sm', 'md', 'lg']}>
                        <Grid className={classes.bordered} item xs={4}>
                        <Link
                          component={RouterLink}
                          to={`/account/view/${user._id}`}
                          variant="h6"
                        >
                          <div className={classes.nameContainer}>
                            <Typography className={classes.username} style={{fontSize: '11px'}} variant="body1">{user.username}</Typography>
                          </div>
                        </Link>
                        </Grid>
                      </Hidden>
                      <Grid className={classes.depositeBonus} item md={4} lg={4} sm={4} xs={8}>
                        {getUserBonusesList(user, 'byDeposits').map(obj =>
                            {
                              return (obj.achievement < obj.goal  && obj.achievement !== 0 &&
                                (<div  key={obj.level}>
                                  <div className={classes.progressBarContainer}>
                                    <LinearProgress
                                      variant="determinate"
                                      className={classes.progressBar}
                                      value={obj.achievement / obj.goal * 100}
                                    /> 
                                    <span style={{fontSize: '11px'}}>{ obj.achievement}/{obj.goal}</span>
                                    <p style={{fontSize: '12px'}}>{t.bonusTypes.byDeposits} / <span className={classes.step}>{t.step} {obj.level}</span></p>
                                  </div>
                                  <div className={classes.bonusContainer}>
                                    <span style={{fontSize: '11px'}}>Bonus</span>
                                    <p className={classes.bonus}>
                                      { Helper.formatMoney(obj.bonus) }
                                    </p>
                                  </div>
                              </div>))
                            })
                        }
                      </Grid>
                      </React.Fragment>
                      }
                      {pageType === "earnings" &&
                      <React.Fragment>
                        <Grid className={classes.bottomBorder} item md={4} sm={4} xs={4} lg={4}>
                          <Link
                            component={RouterLink}
                            to={`/account/view/${user._id}`}
                            variant="h6"
                          >
                            <div className={classes.nameContainer}>
                                <Typography variant="body1">{user.username}</Typography>
                                <StarIcon className={classes.star}/>
                            </div>
                          </Link>
                        </Grid>
                        <Grid className={classes.depositeBonus} item md={4} lg={4} sm={4} xs={4}>
                            <Typography variant="body1">
                              { Helper.formatMoney({amount: user.bonus.commission.overall, currency: user.bonus.commission.currency}) }
                            </Typography>
                        </Grid>
                        <Grid className={classes.depositeBonus} item md={4} lg={4} sm={4} xs={4}>
                          <Typography variant="body1" className={classes.fee}>
                            { Helper.formatMoney({amount: user.bonus.commission.fee, currency: user.bonus.commission.currency}) }
                          </Typography>
                        </Grid>
                      </React.Fragment>
                      }
                    </Grid>
                  ))}
            </Grid>
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

ChildrenTable.propTypes = {
  className: PropTypes.string,
  accounts: PropTypes.array.isRequired,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number
};

export default ChildrenTable;
