import ACTIONS from '../actionsEnum'

export const skin = (state = {}, action) => {
    switch (action.type) {
        case ACTIONS.SET_SKIN_DETAILS:
            return action.skinDetails;
        default:
            return state
    }
};
