import accounting from 'accounting';
import ENUMS from '../configs/enums';
import moment from 'moment';

const Helper = {
  generateUrlQuery(params) {
    if (!params) {
      return '';
    }
    let query = '?query';
    for (let key in params) {
      if (params[key]) {
        query += `&${key}=${params[key]}`;
      }
    }
    return query;
  },
  formatMoney({ amount, currency }) {
    if(!currency) {
      currency = 'EUR';
    }
    return accounting.formatMoney(amount, ENUMS.currencySymbols[currency], 2, ",", ".", "%v%s");
  },
  putDateParams( date ) {
    return {
      dateFrom: moment(date).startOf('month').format('YYYY-MM-DD HH:mm'),
      dateTo: moment(date).endOf('month').format('YYYY-MM-DD HH:mm'),
    };
  }
};

export default Helper;
