import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import StarIcon from '@material-ui/icons/Star';
import Helper from '../../../../common/helper';
import t from '../../../../configs/translations';
import AccountInfo from './AccountInfo'
import { bonusTypes } from '../../../../configs/enums';
import {
  Card,
  Grid,
  IconButton,
  CardContent,
  Typography,
  ListItem,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0
  },
  content: {
    padding: 0
  },
  inner: {
    // minWidth: 1050
    '& .MuiGrid-item': {
      padding: 8
    }
  },
  nameContainer: {
    display: 'flex',
    // justifyContent: 'start',
    height: '100%',
    alignItems: 'center',
    display: 'flex'
  },
  name: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  accountType: {
    textTransform: 'capitalize'
  },
  star: {
    color: `${theme.palette.warning.main}`,
    transform: 'scale(0.7)',
  },
  unstar: {
    color: `${theme.palette.divider}`,
    transform: 'scale(0.7)'
  },
  step: {
    color: theme.palette.primary.main,
    fontSize: 12,
    fontWeight: 500
  },
  fee: {
    color: theme.palette.primary.main,
    alignItems: 'center',
    display: 'flex',
    fontSize: 15
  },
  menuIcon: {
    color: theme.palette.text.dark,
    float: 'left'
  },
  expandIcon: {
    '& .MuiExpansionPanelSummary-expandIcon ':{
      marginLeft: 6,
      marginTop: 3,
      width: 15,
      height: 15,
      transform: 'rotate(0deg)',
      position: 'absolute',
      left: 0,
      backgroundImage:'url(/images/menuIcon.png)',
      backgroundSize: "65% 65%",
      backgroundRepeat: 'no-repeat',
      borderRadius: 0
    },
    '& .MuiExpansionPanelSummary-expandIcon.Mui-expanded ': {
    backgroundImage: 'url(/images/closeIcon.png)'
    }
  },
  expandPanel: {
    '& .MuiExpansionPanelDetails-root': {
      padding: 0
    },
    '& .MuiExpansionPanelSummary-content.Mui-expanded': {
      margin: 0
    },
    '& .MuiExpansionPanelSummary-root': {
      paddingRight: 0
    }
  },
  bordered: {
    borderRight: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  bottomBorder: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  bonus: {
    display: 'flex'
  },
  showFavorites: {
    display: 'none'
  },
  depositBonus: {
    '& p': {
      height: '100%',
      alignItems: 'center',
      display: 'flex'
    },
    '& .MuiLinearProgress-colorPrimary': {
      backgroundColor: theme.palette.info.lighter ,
      height: 10,
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: theme.palette.info.main
    }
  }
}));

const CommissionList = props => {
  const { className, accounts, ...rest } = props;
  const classes = useStyles();
  const [showOnlyFavorites, setShowOnlyFavorites] =useState(false);
  const [isFavorite, setIsFavorite] =useState(false);
  const showFavorites = event => {
    setShowOnlyFavorites(!showOnlyFavorites);
  }
  function setFavorite (event, user) {
    event.stopPropagation();
    user.isFavorite = !user.isFavorite;
    setIsFavorite(!isFavorite); 
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <div className={classes.inner}>
          <Grid container>
            <Grid  item md={5} sm={5} lg={5} xs={5}>
              <ListItem >
                {t.shop}
                <StarIcon className={ showOnlyFavorites ? classes.star : classes.unstar} onClick={showFavorites}/>
              </ListItem>
            </Grid>
            <Grid item md={4} sm={4} lg={4} xs={4}>
              <ListItem>{t.overall}</ListItem>
            </Grid>
            <Grid className={classes.bonus} item md={3} sm={3} lg={3} xs={3}>
              <Typography variant="body1" className={classes.fee}>{t.fee}</Typography>
            </Grid>
            {accounts.map(user => {
              if (!user.bonuses) {
                return '';
              }
              let commission = user.bonuses[bonusTypes.commission] || {};

              return (<Grid key={user.id} container justify="center" alignItems="center" className={showOnlyFavorites && !user.isFavorite ? classes.showFavorites : ''}>
                <ExpansionPanel className={classes.expandPanel} key={user.id}>
                  <ExpansionPanelSummary
                    className={classes.expandIcon}
                    expandIcon={< IconButton />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Grid item md={5} sm={5} xs={5} lg={5}>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1" className={classes.name}>{user.username}</Typography>
                        <StarIcon style={{ fontSize: '2em' }} onClick={event => setFavorite(event, user)} className={user.isFavorite ? classes.star : classes.unstar} />
                      </div>
                    </Grid>
                    <Grid className={classes.depositBonus} item md={4} lg={4} sm={4} xs={4}>
                      <Typography variant="body1">
                        {Helper.formatMoney({ amount: commission.overall, currency: user.bonuses.currency })}
                      </Typography>
                    </Grid>
                    <Grid className={classes.depositBonus} item md={3} lg={3} sm={3} xs={3}>
                      <Typography variant="body1" className={classes.fee}>
                        {Helper.formatMoney({ amount: commission.fee, currency: user.bonuses.currency })}
                      </Typography>
                    </Grid>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <AccountInfo account={user} total={{ amount: commission.fee, currency: user.bonuses.currency }} />
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Grid>)
            })}
          </Grid>
        </div>
      </CardContent>
    </Card>
  );
};

CommissionList.propTypes = {
  className: PropTypes.string,
  accounts: PropTypes.array.isRequired
};

export default CommissionList;
