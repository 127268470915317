import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import Hidden from '@material-ui/core/Hidden';
import t from '../../../../../configs/translations';
import { bonusTypes } from '../../../../../configs/enums';
import {
  Card,
  Grid,
  CardContent,
  Avatar,
  Link,
  Typography,
  ListItem,
} from '@material-ui/core';
import LevelProgress from './LevelProgress';

const avatarUrl = '/images/avatars/anonym.png';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    '& .MuiGrid-item': {
      padding: 4
    }
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  username: {
    position: 'absolute',
    marginTop: '-12px',
    fontSize: '16px'
  },
  bordered: {
    borderRight: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  bottomBorder: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  rightBorder: {
    borderRight: `1px solid ${theme.palette.divider}`
  }
}));

const BonusList = props => {
  const { className, accounts, ...rest } = props;
  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <div className={classes.inner}>
          <Grid container>
            <Hidden only={'xs'}>
              <Grid className={classes.bottomBorder} item md={4} sm={4} lg={4}>
                <ListItem>
                  {t.shop}
                </ListItem>
              </Grid>
              <Grid className={classes.bottomBorder} item md={4} sm={4} lg={4}>
                <ListItem>Players Bonus</ListItem>
              </Grid>
              <Grid className={classes.bottomBorder} item md={4} sm={4} lg={4}>
                <ListItem>Deposits Bonus</ListItem>
              </Grid>
            </Hidden>
          </Grid>

          {accounts.map(user => (
            <Grid key={user.id} container>
              <Grid className={classes.rightBorder} item xs={4}>
                <div className={classes.nameContainer}>
                  <Hidden only={'xs'}>
                    <Avatar className={classes.avatar} src={avatarUrl}>{user.username}</Avatar>
                    <Typography variant="h4">{user.username}</Typography>
                  </Hidden>
                </div>
              </Grid>
              {user.bonuses[bonusTypes.activePlayers] && <LevelProgress bonusData={user.bonuses ? user.bonuses[bonusTypes.activePlayers] : []} type={"player"} />}
              <Hidden only={['sm', 'md', 'lg']}>
                <Grid className={classes.bordered} item xs={4} >
                  <div className={classes.nameContainer}>
                    <Typography className={classes.username} variant="h5">{user.username}</Typography>
                  </div>
                </Grid>
              </Hidden>
              {user.bonuses[bonusTypes.deposit] && <LevelProgress bonusData={user.bonuses ? user.bonuses[bonusTypes.deposit] : []} type={"deposit"} />}
            </Grid>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

BonusList.propTypes = {
  className: PropTypes.string,
  accounts: PropTypes.array.isRequired
};

export default BonusList;
