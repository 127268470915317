import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { updateAccountDetails } from '../../../../actions/Account';
import { makeStyles } from '@material-ui/styles';
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  errMessage: {
    marginLeft: theme.spacing(2),
    marginBottom: 5,
    color: theme.palette.error.main,
    fontSize: 13
  },
  success: {
    backgroundColor: theme.palette.primary.main,
    margin: 5
  },
  error: {
    backgroundColor: theme.palette.error.dark,
    margin: 5
  },
  icon: {
    fontSize: 15,
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    fontSize: 15,
    display: 'flex'
  },
}));

const EditDetails = props => {
  const { className, account, ...rest } = props;
  const authData = useSelector(state => state.authData);
  const error = useSelector(state => state.authData.error);
  const [updated, setUpdated] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const classes = useStyles();
  const dispatcher = useDispatch();
  const [values, setValues] = useState({
    firstName:'',
    lastName: '',
    city: '',
    email: '',
    phone: '',
    country: '',
  });
  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };
  const handleUpdate = event => {
    setTimeout(() => {
      setRedirect(true);
    }, 2000);

    event.preventDefault();
    const update = async () => {
      const response = await updateAccountDetails({params:values, accountId: account._id, token: authData.token, dispatcher: dispatcher});
      setUpdated(true);
    };
    update();
    setRedirect(false)
  };

  useEffect(() => {
    setValues({
      firstName: account.firstname,
      lastName: account.lastname,
      city: account.personalInfo?account.personalInfo.city: '',
      email: account.personalInfo?account.personalInfo.email: '',
      phone: account.personalInfo?account.personalInfo.phoneNumber:'',
      country: account.countryCode,
    });
  }, [account]);


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete="off"
        noValidate
        onSubmit={handleUpdate}
      >
        <CardHeader
          subheader="The information can be edited"
          title="Profile"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                helperText="Please specify the first name"
                label="First name"
                margin="dense"
                name="firstName"
                onChange={handleChange}
                required
                value={values.firstName || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Last name"
                margin="dense"
                name="lastName"
                onChange={handleChange}
                required
                value={values.lastName || ''}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Email Address"
                margin="dense"
                name="email"
                onChange={handleChange}
                required
                value={values.email || ''}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Phone Number"
                margin="dense"
                name="phone"
                onChange={handleChange}
                type="number"
                value={values.phone || ''}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Country"
                margin="dense"
                name="country"
                onChange={handleChange}
                required
                value={values.country || ''}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="city"
                margin="dense"
                name="city"
                onChange={handleChange}
                required
                value={values.city || ''}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        {error &&
        <SnackbarContent
          className={classes.error}
          message= {<span><ErrorIcon className={classes.icon}/>{error}</span>}
        >
        </SnackbarContent>
        }
        {!error && updated &&
        <div>
          <SnackbarContent
            className={classes.success}
            message= {<span><CheckCircleIcon className={classes.icon}/>
              Updated succesfully!</span>}
            autohideduration={500}
          >
            <CheckCircleIcon/>
          </SnackbarContent>
          { redirect &&
          <Redirect to = "/dashboard">
          </Redirect>
          }
        </div>
        }
        <Divider />
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            type="submit"
          >
            Save details
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

EditDetails.propTypes = {
  className: PropTypes.string
};

export default EditDetails;
