import LocalizedStrings from 'react-localization';

let translations = new LocalizedStrings({
  gb: {
    dashboard: "Dashboard",
    commission: "Commission",
    bonusNav: "Bonus",
    total: "Total",
    network: "Network",
    users: "Users",
    profile: "Profile",
    settings: "Settings",
    signOut: "Sign Out",
    username: "Username",
    password: "Password",
    signInNow: "Sign In Now",
    playerBonusModal: "Active Players' Bonus",
    playerBonusModalShop: "Active Players' Bonus",
    playerBonus: "Active Players' Bonus",
    depositBonusModal: "Deposits bonus",
    depositBonusModalShop: "Deposits bonus",
    depositBonus: "Deposits bonus",
    contractBonus: "Immediate Earnings",
    monthlyBonus: "Fixed Commission",
    monthlyInfo: "{0}% lifetime commission from the total deposits of the contractualized PVR.",
    shop: "Shop",
    distributor: "Distributor",
    consultant: "Consultant",
    legend: "Legend",
    view: "View",
    affiliates: "Affiliates",
    skin: "Skin",
    overall: "Overall",
    fee: "Fee",
    step: "Step",
    completed: "Completed",
    congrats: "Congratulations",
    almostAchieviedGoals: "We are almost there",
    newGoalsAchieved: "You have achieved new goals",
    hereAreYourGoals: "Here are the goals you are about to achieve",
    seeYourGoals: "See the goals you are about to achieve",
    achievedGoals: "Goals achieved",
    bonusTotal: "Total Bonus",
    fullTotal: "Full Total",
    downloadInvoice: "Download Invoice",
    bonusTypes: {
      player: "By Active Players",
      deposit: "By Deposits",
    },
    monthlyProfit: "Monthly Income",
    nextGoal: "Next Goal",
    activePlayers: "Active Players",
    deposit: "Deposits",
    forgotPassword: "Forgot Password",
    loginSeller: "Click on User icon to access the seller",
    loginStore: "Click on Store to access PVR",
    shopInfo: "Shop Information",
    role: "Role",
    country: "Country",
    status: "Status",
    activation: "Activation",
    balance: "Balance",
    registeredUsers: "Registered Users",
    monthsActivity: "Months Activity",
    email: "Email",
    playerIsActiveModal: "*Active players are all players that verified their account and deposited at least 50 euro.",
    playerCountBonus: "We support your promotional work, rewarding your results. There is 4000 euro of bonus that expects you. \n" +
      "Register more players to receive more BONUS",
    playerConditions: "*The bonus is cumulative, reaching each step you will receive the respective bonus.Only players that validated their accounts and deposited at least 50 euro will be considered active.",
    additionalBonus: "Additional Bonuses",
    depositCountBonus: "*Earn the maximum from the deposits. Not only the monthly commissions, we reward you with ",
    depositConditions: "The bonus is cumulative, reaching each step you will receive the respective bonus.",
    contractBonusInfo: {
      activationDate: "The reseller will receive {0} for every referred shop.",
      activePlayers: {
        info: "The seller will receive {0} as soon as the Shop has {1} active players.",
        amount: "Active Player = deposits of {0} euro"
      }
    },
    playerBonusInfo: "5 bonus levels, levels are of 100 euro each, levels are reached based on the number of active players* of the shop.",
    depositBonusInfo: "100 euro for every 10k deposits made. Maximum bonus is 500 euro.",
    example: "Example",
    exampleRegistration: "registered a new player account and deposited {0}€.",
    exampleRegistrationFailed: "In this case he won’t be counted as an active player because he deposited less than 50€.",
    exampleRegistrationSucceed: "In this case he will be counted as an active player because he deposited at least 50€",
    noRecords: "No Records",
    seller: "Seller",
    language: "Language",
    updatePassword: "Update password",
    currentPassword: "Current Password",
    newPassword: "New Password",
    confirmPassword: "Confirm Password",
    update: "update",
    totalDeposit: "Total Deposit",
    version: "Version",
    sale: 'Sale',
    totalUsers: 'Total Users',
    totalDeposits: 'Total Deposits',
  },
  it: {
    dashboard: "Riepilogo",
    commission: "Rendita",
    bonusNav: "PREMI",
    bonus: "PREMIO",
    total: "Totali",
    network: "Rete",
    users: "Utenti",
    profile: "Profilo",
    settings: "Impostazioni",
    signOut: "Esci",
    username: "Nome Utente",
    password: "Parola d'ordine",
    signInNow: "Accedi Ora",
    playerBonus: "PREMIO GIOCATORI ATTIVI",
    playerBonusModal: "Premi giocatori attivi",
    playerBonusModalShop: "Premio Registrazione",
    depositBonus: "PREMIO RICARICHE",
    depositBonusModal: "Premi ricariche",
    depositBonusModalShop: "Premio Ricariche",
    contractBonus: "Guadagno Immediato",
    monthlyBonus: "Rendita fissa",
    monthlyInfo: "{0}% di commissioni per tutta la vita sulle ricariche vendute dal PVR contrattualizzato.",
    shop: "PVR",
    distributor: "Distributore",
    consultant: "Consulente",
    legend: "Legenda",
    view: "View",
    affiliates: "Affiliati",
    skin: "Skin",
    overall: "Ricaricato",
    fee: "Compensi",
    step: "Step",
    completed: "Completati",
    congrats: "Complimenti",
    almostAchieviedGoals: "Ci Siamo Quasi",
    newGoalsAchieved: "Hai Raggiunto Nuovi Obiettivi",
    hereAreYourGoals: "Ecco gli obiettivi che stai per raggiungere",
    seeYourGoals: "Vedi gli obiettivi che stai per raggiungere",
    achievedGoals: "Obiettivi Raggiunti",
    bonusTotal: "TOTALE PREMIO PRODUZIONE",
    bonusTotalModal: "Totale premi",
    fullTotal: "Commissioni Maturate",
    downloadInvoice: "Scarica Riepilogo ",
    bonusTypes: {
      player: "Giocatori Attivi",
      deposit: "Ricariche",
    },
    monthlyProfit: "Rendita Mensile",
    nextGoal: "Prossimo Obiettivo",
    activePlayers: "Giocatori Attivi",
    deposit: "Ricariche Vendute",
    forgotPassword: "Password Dimenticata",
    loginSeller: "Clicca su user per accedere a venditore",
    loginStore: "Clicca su Negozio per accedere a PVR",
    shopInfo: "Informazioni PVR",
    role: "Ruolo",
    country: "Nazione",
    status: "Stato",
    activation: "Attivazione",
    balance: "Equilibrio",
    registeredUsers: "Utenti Registrati",
    monthsActivity: "Mesi Attività",
    email: "E-mail",
    playerCountBonus: "Premiamo il tuo lavoro di promozione del nostro marchio. Fino a {0} euro di premi per i giocatori attivi presenti nella tua rete.",
    playerConditions: "*Ai fini del conteggio saranno presi in considerazione gli utenti che hanno validato il proprio conto gioco ed effettuato depositi per un totale di 50 euro.",
    additionalBonus: "Bonus Aggiuntivi",
    depositCountBonus: "Aggi maggioranti sulle prime {0} euro di ricariche vendute. Sblocca fino a {1} di premi aggiuntivi. Più ricarichi e più ti Ricarichi!",
    depositConditions: "*La tua ricompensa è cumulativa, al raggiungimento di ogni step riceverai il premio designato.",
    depositConditionsMonth: "*La tua ricompensa è cumulativa, al raggiungimento di ogni step riceverai il premio designato. Il premio produzione è considerato valido per {0} mesi, a partire dell'attivazione del pacchetto.",
    contractBonusInfo: {
      activationDate: "Il Venditore percepirà {0} per ogni Punto Vendita contrattualizzato.",
      activePlayers: {
        info: "Il Venditore percepirà {0} non appena il Punto Vendita contrattualizzato avrà {1} giocatori attivi.",
        amount: "Giocatore Attivo = {0} depositati"
      }
    },
    playerBonusInfo: "Fino a {0}€ di premi produzione legati al numero di giocatori attivi presenti nella sottorete del punto vendita ricarica, {1} livelli di premi da {2}€ ciascuno.",
    depositBonusInfo: "Fino a {0}€ di premi produzione legati al numero di ricariche vendute, {1}€ per ogni 10.000,00 euro di ricariche vendute.",
    example: "Esempio",
    exampleRegistration: "ha registrato un nuovo conto gioco e ha versato un totale di {0}€",
    exampleRegistrationFailed: "In questo caso non verrà conteggiato come utente per il bonus perché non ha ricaricato almeno 50€",
    exampleRegistrationSucceed: "In questo caso verrà conteggiato come utente per il bonus perché ha ricaricato almeno 50€",
    playerIsActiveModal: "*Per Giocatore Attivo si intende un utente che ha identificato il conto e ha effettuato ricariche di almeno 50 euro.",
    noRecords: "Nessuna Registrazione",
    seller: "Venditore",
    language: "Lingua",
    updatePassword: "Cambio Password",
    currentPassword: "Password Attuale",
    newPassword: "Nuova Password",
    confirmPassword: "Ripeti Password",
    update: "Aggiorna",
    totalDeposit: "Ricariche",
    version: "Versione",
    sale: 'Saldo',
    totalUsers: 'Numero utenti',
    totalDeposits: 'Totale ricariche',
  }
});

export default translations;
