import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Doughnut } from 'react-chartjs-2';
import accounting from 'accounting';
import t from '../../../../../configs/translations';

import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Divider,
  Typography
} from '@material-ui/core';
import RefreshIcon from '@material-ui/core/SvgIcon/SvgIcon';


const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  header: {
    textAlign: "center",
    color: theme.palette.primary.main,
    marginTop: "5%"
  },
  progress: {
    marginTop: theme.spacing(2)
  },
  title: {
    fontWeight: 700
  },
  chartContainer: {
    position: 'relative',
    height: 275
  },
  stats: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  earning: {
    textAlign: 'center',
    padding: theme.spacing(1)
  },
}));

// Some copy/paste from stackoverflow to show text inside the chart
//
// var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
// Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
//   draw: function() {
//     originalDoughnutDraw.apply(this, arguments);
//
//     var chart = this.chart.chart;
//     var ctx = chart.ctx;
//     var width = chart.width;
//     var height = chart.height;
//
//     var fontSize = (height / 114).toFixed(2);
//     ctx.font = fontSize + "em Verdana";
//     ctx.textBaseline = "middle";
//
//     var text = chart.config.data.text,
//       textX = Math.round((width - ctx.measureText(text).width) / 2),
//       textY = height / 2;
//
//     ctx.fillText(text, textX, textY);
//   }
// });

const Earnings = props => {
  const { className, earned, pending, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const data = {
    datasets: [
      {
        data: [
          earned ? (earned.amount / 10000) : 0,
          pending ? (pending.amount / 10000) : 0
        ],
        backgroundColor: [
          theme.palette.primary.main,
          theme.palette.warning.main
        ],
        borderWidth: 20,
        borderColor: theme.palette.white,
        hoverBorderColor: theme.palette.white
      }
    ],
    labels: ['Earned', 'Pending']
  };

  const options = {
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    showTooltips: true,
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary
    }
  };


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Typography variant="h3" className={classes.header}>{t.monthlyProfit}</Typography>
        {/*<Divider />*/}
        <CardContent>
          <div className={classes.chartContainer}>
            <Doughnut
              data={data}
              options={options}
            />
          </div>
      </CardContent>
    </Card>
  );
};

Earnings.propTypes = {
  className: PropTypes.string
};

export default Earnings;
