import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import t from '../../../../../../configs/translations';
import Helper from '../../../../../../common/helper';

const useStyles = makeStyles(theme => ({
    bonusContent: {
        color: theme.palette.primary.main,
        marginBottom: 20
      },
      infoText: {
        color: theme.palette.black,
        padding: 3
      },
      note: {
        color: theme.palette.primary.main,
        padding: 3,
        fontStyle: 'italic',
        marginTop: theme.spacing(1)
      }
}));

const MonthlyInfoDialog = props => {
    const classes = useStyles();
    const { data } = props;

    return(
        <React.Fragment>
            <Typography variant='h4' className={classes.bonusContent}>
                {t[`monthlyBonus`]}
            </Typography>
            <Typography variant='body2' className={classes.infoText}>
                {t.formatString(t[`monthlyInfo`], Number(data).toFixed(2))}
            </Typography>
        </React.Fragment>
    );
};

export default MonthlyInfoDialog;
