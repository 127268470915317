import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import SettingsIcon from '@material-ui/icons/Settings';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import t from '../../../../configs/translations';
import { SidebarNav, UpgradePlan } from './components';
import { Profile } from './components';
import GitVersion from './components/GitVersion';
// import { ProfileElem } from '../../../../containers/Bars'

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: "9%",
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  const pages = [
    {
      title: `${t.dashboard}`,
      href: `/dashboard`,
      icon: <DashboardIcon />
    },
    {
      title: `${t.network}`,
      href: `/network`,
      icon: <PeopleIcon />
    },
    {
      title: `${t.users}`,
      href: `/players`,
      icon: <PeopleIcon />
    },
    // {
    //   title: 'Products',
    //   href: `/products`,
    //   icon: <ShoppingBasketIcon />
    // },
    // {
    //   title: `${t.profile}`,
    //   href: `/account/view`,
    //   icon: <AccountBoxIcon />
    // },
    {
      title: `${t.settings}`,
      href: `/settings`,
      icon: <SettingsIcon />
    }
  ];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        {/* <ProfileElem /> */}
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav
          className={classes.nav}
          pages={pages}
          onClose={onClose}
        />
        {/*<UpgradePlan />*/}
        <GitVersion />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
