import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import ENUMS from '../../../../../configs/enums';
import TreeItem from "@material-ui/lab/TreeItem";
import Person from "@material-ui/icons/Person";
import { Link, Typography, Grid } from '@material-ui/core';
import ACTIONS from '../../../../../store/actionsEnum';
import PersonIcon from "@material-ui/icons/Person";
import Brightness1Icon from '@material-ui/icons/Brightness1';
import VisibilityIcon from '@material-ui/icons/Visibility';
import RemoveIcon from '@material-ui/icons/Remove';
import Helper from './../../../../../common/helper';

const higherLevelAccounts = [ENUMS.roleIds.consultant, ENUMS.roleIds.distributor, ENUMS.roleIds.company];

const roleColors = {
  company: "#34a853",
  consultant: "#4285f4",
  distributor: '#fbbc05',
  seller: '#ea4335',
  salesman: '#ea4335',
  shop: '#5ac194',
  player: '#5ac194'
}

const useTreeItemStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.secondary,
    '&.header': {
      backgroundColor: '#f5f5f5',
      '& .MuiTreeItem-iconContainer': {
        display: "none",
      }
    },
    '& .MuiTreeItem-label': {
      padding: 0,
    },
    '& .MuiTreeItem-iconContainer': {
      marginRight: 0,
      color: theme.palette.primary.contrastText,
      backgroundColor: "#fbbc05",
      alignItems: 'center',
      height: '4.5em',
    },
    '&.shop': {
      '& .MuiTreeItem-iconContainer': {
        backgroundColor: "#5ac194",
        '& .MuiSvgIcon-root': {
          display: "none",
        }
      }
    },
    '&.company .MuiTreeItem-iconContainer': {
      backgroundColor: "#34a853"
    },
    '&.consultant .MuiTreeItem-iconContainer': {
      backgroundColor: "#4285f4"
    },
    '&.distributor .MuiTreeItem-iconContainer': {
      backgroundColor: "#fbbc05"
    },
    '&.seller .MuiTreeItem-iconContainer': {
      backgroundColor: "#ea4335"
    },
    '&.salesman .MuiTreeItem-iconContainer': {
      backgroundColor: "#ea4335"
    },
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "$expanded > &": {
      fontWeight: theme.typography.fontWeightRegular
    }
  },
  group: {},
  expanded: {},
  label: {
    paddingTop: "8px",
    fontWeight: "inherit",
    color: "inherit",
    borderBottom: "solid 1px #dad5d5",
    lineHeight: "3em"
  },
  labelRoot: {
    // display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0)
  },
  labelIcon: {
    color: '#bab9b9',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  treeItem: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    height: "5em",
    textAlign: 'center',
    borderBottom: "1px solid #e8d8d8"
  },
  rowElement: {
    flexDirection: "column",
    color: theme.palette.primary.main,
    flex: 1,
    textAlign: "center",
  },
  children: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  negativeBalance: {
    color: "#ea4335"
  }
}));

const StyledTreeItem = props => {
  const classes = useTreeItemStyles();
  const [roleCounts, setRoleCounts] = useState({});
  const { user, labelIcon: LabelIcon, color, bgColor, ...other } = props;
  const isShop = user.subTypeId === ENUMS.roleIds.shop;
  const authData = useSelector(state => state.authData);
  const dispatcher = useDispatch();
  const history = useHistory();

  let showVisibilityIcon = false;
  if (authData.accountId !== user._id && higherLevelAccounts.includes(authData.subTypeId)) {
    showVisibilityIcon = true;
  }

  let wallet = null;
  if (isShop) {
    const accountWallet = user.wallets.find(w => w.currency == 'EUR');
    if (accountWallet && accountWallet.negativeBalance < 0) {
      wallet = {
        balance: accountWallet.negativeBalance,
        currency: accountWallet.currency
      }
    }
  } else if (user.networkBalance) {
    const w = user.networkBalance;
    wallet = {
      balance: w.negativeBalance,
      currency: w.currency
    }
  }

  const handleViewTool = useCallback(() => {
    if (showVisibilityIcon) {
      dispatcher({ type: ACTIONS.SET_ACCOUNT, accountId: user._id, subTypeId: user.subTypeId });
      history.push(`/dashboard`);
    } else {
      dispatcher({ type: ACTIONS.UNSET_ACCOUNT });
      history.push(`/account/view/${user._id}`);
    }
  }, [user, authData, showVisibilityIcon]);

  useEffect(() => {
    if (authData.subTypeId == ENUMS.roleIds.shop) {
      history.push(`/dashboard`);
    }
  }, []);

  useEffect(() => {
    setRoleCounts(countRoles(user));
  }, [user]);

  const countRoles = tmpUser => {
    let result = {};

    if (tmpUser.children && tmpUser.children.length) {
      for (let account of tmpUser.children) {
        if (!account.subTypeId) continue;
        if (!result[account.subTypeId]) {
          result[account.subTypeId] = 1;
        } else {
          result[account.subTypeId]++;
        }
      }
    }

    return result;
  };


  return (
    <TreeItem className={ENUMS.roleNames[user.subTypeId]}
      label={
        <div className={classes.treeItem}>
          <Grid container spacing={2} justify="center">
            <Grid item xs={3}>
              <Typography variant="h6" color="inherit">{user.username}</Typography>
            </Grid>
            <Grid item xs={3}>
              {(!!roleCounts && !isShop) ?
                Object.keys(roleCounts).map(roleId => {
                  const iconColor = roleColors[ENUMS.roleNames[roleId]];
                  return (
                    <div key={roleId} className={classes.rowElement}>
                      <Link component={RouterLink} className={classes.children}
                        to={`/children/bonus/${user._id}`}
                        variant="h6" >
                        <Brightness1Icon style={{ fill: iconColor }} /> {roleCounts[roleId]}
                      </Link>
                    </div>
                  )
                }) : (isShop ?
                  <Link component={RouterLink} className={classes.children}
                    to={`/players/${user._id}`}
                    variant="h6" >
                    <PersonIcon className={classes.labelIcon} />
                    {user.childrenCount[ENUMS.accountTypeIds.player]}
                  </Link>
                  : <RemoveIcon style={{ fill: '#bab9b9' }} />)
              }
            </Grid>
            <Grid item xs={3}>
              {showVisibilityIcon ?
                <div className={classes.rowElement} onClick={handleViewTool}>
                  <VisibilityIcon style={{ fill: '#bab9b9' }} />
                </div>
                : <RemoveIcon style={{ fill: '#bab9b9' }} />
              }
            </Grid>
            <Grid item xs={3}>
              {wallet ?
                <Typography variant="h6" className={classes.negativeBalance}>
                  {Helper.formatMoney({ amount: wallet.balance, currency: wallet.currency })}
                </Typography>
                : <RemoveIcon style={{ fill: '#bab9b9' }} />}
            </Grid>
          </Grid>
        </div>
      }
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        group: classes.group
      }}
      {...other}
    />
  );
}
StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  user: PropTypes.object.isRequired,
};

export const TreeOfAccounts = props => {
  let { usersList } = props;
  console.log("TREE users: ", usersList);
  if (!!usersList) {
    usersList = usersList.sort((a, b) => a.username.localeCompare(b.username));
  }

  return (
    <React.Fragment>
      { !!usersList &&
        usersList.map(user => (
          <StyledTreeItem key={user._id} labelIcon={Person} nodeId={user.username} key={user._id} user={user}>
            <TreeOfAccounts usersList={user.children} ></TreeOfAccounts>
          </StyledTreeItem>
        ))}
    </React.Fragment>
  )
}

