import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import ENUMS from '../../../configs/enums';

import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Checkbox,
  Table,
  Link,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination
} from '@material-ui/core';

// import { getInitials } from 'helpers';
const avatarUrl = '/images/avatars/anonym.png';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    // minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  accountType: {
    textTransform: 'capitalize'
  }
}));

const StatementsTable = props => {
  const { className, statements, rowsPerPage, page, ...rest } = props;
  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  {/*<TableCell>TransactionID</TableCell>*/}
                  <TableCell>Date</TableCell>
                  <TableCell>Sender</TableCell>
                  <TableCell>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {statements.map(statement => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={statement._id}
                  >
                    {/*<TableCell>{statement._id}</TableCell>*/}
                    <TableCell>{moment(statement.dateUpdated).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                    <TableCell>
                      <Link
                        component={RouterLink}
                        to={`/account/${statement.source._id}`}
                        variant="h6"
                      >
                        {statement.source.username}
                      </Link>
                    </TableCell>
                    <TableCell>{statement.amount}{statement.currency}</TableCell>
                    {/*<TableCell>{statement.walletsAfter[0].balance} {statement.walletsAfter[0].currency}</TableCell>*/}
                    {/*<TableCell>{statement.status}</TableCell>*/}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

StatementsTable.propTypes = {
  className: PropTypes.string,
  statements: PropTypes.array.isRequired,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number
};

export default StatementsTable;
