import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Switch, Redirect } from 'react-router-dom';
import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';
import { authenticationCheck,setSkinAction } from "./actions/Account";
import t from './configs/translations';
import Configs from './configs/configs';

import {
  Dashboard as DashboardView,
  ProductList as ProductListView,
  UserList as UserListView,
  AccountView as ViewAccountView,
  AccountEdit as EditAccountView,
  BonusEdit as EditBonusView,
  AccountAdd as AddAccountView,
  Players as PlayersView,
  Statements as StatementsView,
  Settings as SettingsView,
  SignIn as SignInView,
  SignUp as SignUpView,
  NotFound as NotFoundView,
  Children as ChildrenView,
  Loader as LoaderView,
  CommissionTab as CommissionTabView,
  BonusTab as BonusTabView
} from './components/views';

const Routes = props => {

  const [authenticated, setAuthenticated] = useState(false);
  const authDataXX = useSelector(state => state.authData);
  const dispatchX = useDispatch();
  console.log('LOCATION: ', window.location.href);

  const checkAuthentication = () => {
    if (localStorage.authenticationData) {
      authenticationCheck(dispatchX);
    }
  };

  useEffect(() => {
    // console.log('Routes useEFfect-1');
    checkAuthentication();

    setSkinAction(dispatchX);

    // Set the language if it's not set yet
    //
    if (!localStorage.language) {
      localStorage.setItem('language', Configs.languages[0]);
    }
    t.setLanguage(localStorage.language);
  }, []);

  useEffect(() => {
    // console.log('Routes useEFfect-2');
    setAuthenticated(!!authDataXX.token);
  }, [authDataXX.token]);

  return (
    <React.Fragment>
      {
        authenticated ? (
          <React.Fragment>
            <Switch>
              <Redirect
                exact
                from="/"
                to={`/dashboard`}
              />
              <RouteWithLayout
                component={DashboardView}
                exact
                layout={MainLayout}
                path={`/dashboard`}
              />
              <RouteWithLayout
                component={UserListView}
                exact
                layout={MainLayout}
                path={`/network`}
              />
              <RouteWithLayout
                component={ProductListView}
                exact
                layout={MainLayout}
                path={`/products`}
              />
              <RouteWithLayout
                component={SettingsView}
                exact
                layout={MainLayout}
                path={`/settings`}
              />
              <RouteWithLayout
                component={SignUpView}
                exact
                layout={MinimalLayout}
                path={`/sign-up`}
              />
              <RouteWithLayout component={ViewAccountView} exact layout={MainLayout} path={`/account/view/:id`} />
              <RouteWithLayout component={ViewAccountView} exact layout={MainLayout} path={`/account/view`} />
              <RouteWithLayout component={EditAccountView} exact layout={MainLayout} path={`/account/edit/:id`} />
              <RouteWithLayout component={EditAccountView} exact layout={MainLayout} path={`/account/edit`} />
              <RouteWithLayout component={EditBonusView} exact layout={MainLayout} path={`/bonus/edit/:id`} />
              <RouteWithLayout component={EditBonusView} exact layout={MainLayout} path={`/bonus/edit`} />
              <RouteWithLayout component={AddAccountView} exact layout={MainLayout} path={`/account/create`} />
              <RouteWithLayout
                component={PlayersView}
                exact
                layout={MainLayout}
                path={`/players/:id`}
              />
              <RouteWithLayout
                component={PlayersView}
                exact
                layout={MainLayout}
                path={`/players`}
              />
              <RouteWithLayout component={ChildrenView} exact layout={MainLayout} path={`/children/:page/:accountId/:roleType`} />
              <RouteWithLayout component={ChildrenView} exact layout={MainLayout} path={`/children/:page/:accountId`} />
              <RouteWithLayout component={ChildrenView} exact layout={MainLayout} path={`/children/:page`} />

              <RouteWithLayout component={CommissionTabView} exact layout={MainLayout} path={`/commission`} />
              <RouteWithLayout component={BonusTabView} exact layout={MainLayout} path={`/bonus`} />
              {/*<RouteWithLayout component={ChildrenView} exact layout={MainLayout} path={`/children`} />*/}
              <RouteWithLayout
                component={StatementsView}
                exact
                layout={MainLayout}
                path={`/statements/:id`}
              />
              <RouteWithLayout
                component={StatementsView}
                exact
                layout={MainLayout}
                path={`/statements`}
              />
              {/* <RouteWithLayout
                component={LoginContainer}
                exact
                layout={MinimalLayout}
                path="/sign-in"
              /> */}
              <RouteWithLayout
                component={NotFoundView}
                exact
                layout={MinimalLayout}
                path={`/not-found`}
              />
              <Redirect to={`/dashboard`} />
            </Switch>
          </React.Fragment>
          // ) : <Redirect to="/sign-in" />
        ) : <SignInView />}
      <LoaderView />
    </React.Fragment >
  )
};

export default Routes;
