import React from 'react';
import ENUMS from '../../../../../configs/enums';
import { makeStyles } from '@material-ui/styles';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import { Typography, Grid, Paper } from '@material-ui/core';
import t from '../../../../../configs/translations';
import { useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '10px',
    backgroundColor: '#f5f5f5'
  },
  content: {
    flexGrow: 1,
    '& > *': {
      width: theme.spacing(16)
    },
  },
  consultant: {
    fill: "#4285f4",
    fontSize: '1em',
    marginRight: '2%'
  },
  company: {
    fill: "#34a853",
    fontSize: '1em',
    marginRight: '2%'
  },
  distributor: {
    fill: '#fbbc05',
    fontSize: '1em',
    marginRight: '2%'
  },
  seller: {
    fill: '#ea4335',
    fontSize: '1em',
    marginRight: '2%'
  },
  shop: {
    fill: '#5ac194',
    fontSize: '1em',
    marginRight: '2%'
  },
  role: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.7em'
  },
  item: {
    marginRight: "3%"
  }
}));

const UserRoles = () => {
  const classes = useStyles();
  const authDataXX = useSelector(state => state.authData);
  const subTypeName = ENUMS.roleNames[authDataXX.subTypeId];

  const roles = {
    shop: [ENUMS.roleIds.shop],
    salesman: [
      ENUMS.roleIds.seller,
      ENUMS.roleIds.shop
    ],
    seller: [
      ENUMS.roleIds.seller,
      ENUMS.roleIds.shop
    ],
    distributor: [
      ENUMS.roleIds.distributor,
      ENUMS.roleIds.seller,
      ENUMS.roleIds.shop
    ],
    consultant: [
      ENUMS.roleIds.consultant,
      ENUMS.roleIds.distributor,
      ENUMS.roleIds.seller,
      ENUMS.roleIds.shop
    ],
    company: [
      ENUMS.roleIds.company,      
      ENUMS.roleIds.consultant,
      ENUMS.roleIds.distributor,
      ENUMS.roleIds.seller,
      ENUMS.roleIds.shop
    ]
  }

  return (
    <Paper className={classes.root}>
      <Grid container className={classes.content} spacing={2} justify="center">
        <Grid item xs={12}>
          <Grid container direction="row" justify="center" spacing={1}>
            <Typography variant="body1">{t.legend}:</Typography>
          </Grid>
        </Grid>
        <Grid item xs={10}>
          <Grid container direction="row" alignItems="center" justify="center" wrap='nowrap'>
            {subTypeName && roles[subTypeName].map(typeId => {
              let r = ENUMS.roleNames[typeId];
              return (
                <Grid key={typeId} item className={classes.item}>
                  <Typography className={classes.role}>
                    <Brightness1Icon className={classes[r]} />
                    {t[typeId == 1 ? 'skin': r]}
                  </Typography>
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default UserRoles;
