import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import Helper from '../../../../common/helper';
import t from '../../../../configs/translations';

import {
  Grid,
  Paper,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    margin: "1% 0",
    padding: "5%",
    borderRadius: 0,
    background: `linear-gradient(0deg, ${theme.palette.primary.main} 50%, ${theme.palette.primary.dark} 98%)`,
    '& h5': {
      color: theme.palette.white,
    }
  }
}));


const Total = props => {
  const { className, data, ...rest } = props;
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={5}>
          <Typography variant="h5">{t.total}</Typography>
        </Grid>
        { data &&
            <React.Fragment>
              <Grid item xs={4}>
                <Typography variant="h5">{Helper.formatMoney({amount: data.overall, currency: data.currency})}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="h5">{Helper.formatMoney({amount: data.fee, currency: data.currency})}</Typography>
              </Grid>
            </React.Fragment>
        }
      </Grid>
    </Paper>
  );
};

Total.propTypes = {
  className: PropTypes.string
};

export default Total;
