import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import TreeView from "@material-ui/lab/TreeView";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { TreeOfAccounts } from "./UsersTree";
import ACTIONS from 'store/actionsEnum';
import { Card, CardContent, Typography, ListItem, Grid, Divider, Icon } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import t from '../../../../../configs/translations';
import TreeItem from "@material-ui/lab/TreeItem";

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '40px',
  },
  header: {
    flexGrow: 1,
    '& > *': {
      width: theme.spacing(16)
    },
  },
  content: {
    padding: 0
  },
  roles: {
    color: theme.palette.primary.main,
    marginLeft: 5,
    marginTop: 10
  },
  roleContainer: {
    color: theme.palette.primary.main,
    marginBottom: "3%"
  },
  role: {
    width: 'auto',
  },
  treeItem: {
    backgroundColor: '#f5f5f5',
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    height: "5em",
    textAlign: 'center',
    borderBottom: "1px solid #e8d8d8"
  },
}));

const UsersTable = props => {
  const { className, users, ...rest } = props;
  const classes = useStyles();
  const dispatcher = useDispatch();
  dispatcher({ type: ACTIONS.UNSET_ACCOUNT });

  return (
    <div className={classes.main}>
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardContent className={classes.content}>
          <TreeView
            className={classes.root}
            defaultExpanded={["3"]}
            defaultCollapseIcon={<ArrowDropDownIcon />}
            defaultExpandIcon={<ArrowRightIcon />}
            defaultEndIcon={<div style={{ width: 24 }} />}
          >
            <TreeItem className='header' style={{ backgroundColor: '#f5f5f5'}}
              label={
                <div className={classes.treeItem}>
                  <Grid container spacing={2} justify="center">
                      <Grid container direction="row" alignItems="center">
                        <Grid item xs={3}>
                          <Typography >{t.username}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography >{t.affiliates}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography >{t.view}</Typography>
                        </Grid>
                        <Grid item xs={3} >
                          <Typography >{t.sale}</Typography></Grid>
                      </Grid>
                  </Grid>
                </div>
              }
              nodeId='header'
            />
            <TreeOfAccounts usersList={users} />
          </TreeView>
        </CardContent>
      </Card>
    </div>
  );
};

UsersTable.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired
};

export default UsersTable;
