import React from 'react';
import ENUMS from '../../../../../configs/enums';
import { useSelector } from 'react-redux';
import { ShopInfoDialog, SellerInfoDialog, MonthlyInfoDialog } from './components';

const InfoDialog = props => {
  const { type, data } = props;
  const roleId = useSelector(state => state.authData.subTypeId);
  const selectedAccount = useSelector(state => state.selectedAccount);
  let isShop = roleId === ENUMS.roleIds.shop;
  const isDistributor = roleId === ENUMS.roleIds.distributor;
  const isConsultant = roleId === ENUMS.roleIds.consultant;
  if ((isConsultant || isDistributor) && selectedAccount.subTypeId) {
    isShop = selectedAccount.subTypeId === ENUMS.roleIds.shop
  }

  return(
    <React.Fragment>
        {
          type === 'monthly' ? <MonthlyInfoDialog data={data}/> :
          isShop ? <ShopInfoDialog data ={data} type={type}/> :
            <SellerInfoDialog data ={data} type={type}/>
        }
    </React.Fragment>
  )
};

export default InfoDialog;
