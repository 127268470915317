import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import t from '../../../../../../configs/translations';
import Level from './Level';
import { bonusTypes } from '../../../../../../configs/enums';
import Helper from '../../../../../../common/helper';

const useStyles = makeStyles(theme => ({
    bonusContent: {
        color: theme.palette.primary.main,
        marginBottom: 20
    },
    infoText: {
        color: theme.palette.black,
        padding: 3
    },
    note: {
        color: theme.palette.primary.main,
        padding: 3,
        fontStyle: 'italic',
        marginTop: theme.spacing(1)
    },
    bonusType: {
        minHeight: theme.spacing(8),
        background: `linear-gradient(135deg, ${theme.palette.text.lighter} 59%, ${theme.palette.divider} 95%)`,
        borderRadius: '15%',
        color: theme.palette.primary.main,
        textAlign: 'center',
        padding: theme.spacing(1),
        marginTop: theme.spacing(2),
        width: '88%'
    },
    totalBonus: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.white,
        borderRadius: '9%',
        marginLeft: theme.spacing(1),
        fontSize: '0.8em',
        padding: 5
    },
    icon: {
        height: 50,
        width: 50
    },
    fail: {
        color: theme.palette.error.main
    },
    success: {
        color: theme.palette.primary.main
    }
}));

const ImmediateEarningBonusInfo = props => {
    const classes = useStyles();
    const { data = {} } = props;
    const details = data.details || {};
    const currency = data.currency || "EUR";
    const amount = Helper.formatMoney({ amount: details.amount, currency: currency });
    const activePlayerAmount = Helper.formatMoney({ amount: details.activePlayerAmount, currency: currency });

    return (
        <React.Fragment>
            { details.trigger == 'activePlayers'
                ? <React.Fragment>
                    <Typography variant='h5' className={classes.infoText}>
                        {t.formatString(t.contractBonusInfo.activePlayers.info,
                            amount, details.activePlayersCount)}
                    </Typography>
                    <Typography variant='h5' className={classes.infoText}>
                        {t.formatString(t.contractBonusInfo.activePlayers.amount,
                            activePlayerAmount)}
                    </Typography>
                </ React.Fragment>
                : <Typography variant='h5' className={classes.infoText}>
                    {t.formatString(t.contractBonusInfo.activationDate, amount)}
                </Typography>}
        </React.Fragment>
    )
};

const BonusInfo = props => {
    const classes = useStyles();
    const { type, data = {} } = props;
    const details = data.details || {};
    const currency = data.currency || "EUR";
    let total = 0;
    const levelsCount = details.levels ? details.levels.length : 0;
    const levelsAmount = details.levels[0].amount || 0;
    details.levels && details.levels.map((l, i) => {
        total += l.amount;
    });

    return (
        <React.Fragment>
            <Typography variant='body2' className={classes.infoText}>
                {type === "player" ? t.formatString(t[`${type}BonusInfo`], total, levelsCount, levelsAmount)
                    : t.formatString(t[`${type}BonusInfo`], total, levelsAmount)}
            </Typography>
            <Grid container>
                <Grid item xs={4}>
                    <Typography variant='body2' className={classes.bonusType}>
                        {t[`${type}BonusModal`]}
                    </Typography>
                    <Typography variant='body2' className={classes.note}>
                        {t[`${type}IsActiveModal`]}
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <div style={{ position: 'relative' }}>
                        {details.levels && details.levels.map(l => {
                            return (<Level currency={currency}
                                key={l.threshold}
                                type={type}
                                goal={l.threshold}
                                bonus={l.amount}
                            />)
                        })}
                    </div>
                    <Typography variant='h5' style={{ textAlign: 'right' }} className={classes.note}>
                        {t.bonusTotalModal}
                        <span className={classes.totalBonus}>
                            {Helper.formatMoney({ amount: total, currency: currency })}
                        </span>
                    </Typography>
                </Grid>
            </Grid>
        </React.Fragment>
    )
};

const SellerInfoDialog = props => {
    const classes = useStyles();
    const { type, data } = props;
    const isImmediateEarning = data.type == bonusTypes.immediateEarning;

    return (
        <React.Fragment>
            <Typography variant='h4' className={classes.bonusContent}>{t[`${type}BonusModal`]}</Typography>
            {isImmediateEarning ? <ImmediateEarningBonusInfo data={data} />
                : <BonusInfo data={data} type={type} />}
        </React.Fragment>
    );
};

export default SellerInfoDialog;
