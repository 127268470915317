import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import t from '../../../../../configs/translations';
import Configs from '../../../../../configs/configs';
import Flag from 'react-world-flags'

import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid
} from '@material-ui/core';

const useStyles = makeStyles(theme  => ({
  root: {},
  flagItem: {
    height: "10vh"
  },
  flagContainer: {
    textAlign: "center",
    '&.selected': {
      border: `2px solid ${theme.palette.primary.main}`
    }
  }

}));

const LanguageSelector = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const [currentLanguage, setCurrentLanguage] = useState(t.getLanguage());

  const setLanguage = lang => {
    setCurrentLanguage(lang);
    t.setLanguage(lang);
    localStorage.setItem('language', lang);
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title={t.language}
      />
      <Divider />
      <CardContent>

        <Grid container spacing={3}>
          {
            Configs.languages.map(lang => (
              <Grid
                key={lang}
                item xs={6}
                className={clsx(classes.flagContainer, lang === currentLanguage ? "selected" : "")}
                onClick={() => setLanguage(lang)}
              >
                  <Flag className={classes.flagItem} code={ lang } />
              </Grid>
            ))
          }
        </Grid>
      </CardContent>
    </Card>
  );
};


LanguageSelector.propTypes = {
  className: PropTypes.string
};

export default LanguageSelector;
