import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link as RouterLink, withRouter } from 'react-router-dom';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ErrorIcon from '@material-ui/icons/Error';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import t from '../../configs/translations';
import VpnKeyRoundedIcon from '@material-ui/icons/VpnKeyRounded';
import PersonIcon from '@material-ui/icons/Person';
import Radio from '@material-ui/core/Radio';
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Link,
  Typography
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { userLoginAction } from "../../actions/Account";

import { Facebook as FacebookIcon, Google as GoogleIcon } from 'icons';

const schema = {
  username: {
    presence: { allowEmpty: false, message: 'is required' },
    // email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundImage: 'url(/images/loginBackground.png)',
    backgroundSize: '300% 185%',
    backgroundRepeat: 'no-repeat',
    height: '100%',
    fontFamily: '"Lato", sans-serif'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoContainer : {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '15vh',
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
  },
  roleButton: {
    height: 70,
    width: 70
  },
  selectedRoleButton:{
    height: 70,
    width: 70,
    border: `1px solid ${theme.palette.primary.light} `
  },
  logoImage: {
    margin: '0 auto',
    maxWidth: '290px',
    maxHeight: '90px',
  },
  contentBody: {
    // flexGrow: 1,
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    backgroundColor: theme.palette.white,
    borderRadius: '15%',
    border: 'solid',
    borderColor: theme.palette.divider,
    borderWidth: '3px',
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 25,
    flexBasis: 300
  },
  chooseRole: {
    color: theme.palette.text.main
  },
  loginRole: {
    color: theme.palette.text.main,
    margin: 'auto',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  roleIcons: {
    marginTop: theme.spacing(1),
    textAlign: 'center'
  },
  label: {
    color: theme.palette.text.main,
    fontSize: '18px',
    marginTop: theme.spacing(3),
    display: 'block',
    fontWeight: '600',
  },
  icon: {
    color: theme.palette.text.lighter,
    transform: 'scale(1.5)'
  },
  keyIcon: {
    color: theme.palette.text.lighter,
    transform: 'rotate(135deg) scale(1.5)'
  },
  userIcon: {
    transform: 'scale(2.3)',
    color: theme.palette.primary.main
  },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    '& .MuiFilledInput-underline:before': {
      border:'none'
    },
  },
  error: {
    marginTop: 5,
    backgroundColor: theme.palette.error.dark
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  messageIcon: {
    fontSize: 15,
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  errorMessage: {
    '& .MuiSnackbarContent-root' : {
      padding: 'none',
      marginTop: 10
    },
    marginTop: theme.spacing(1),
  },
  forgotPassword: {
    float: 'right'
  },
  signInButton: {
    margin: theme.spacing(3, 0, 1, 0),
    textTransform: 'capitalize',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
  }
}));

const SignIn = props => {
  const { history } = props;
  const classes = useStyles();
  const dispatcher = useDispatch();
  const authData = useSelector(state => state.authData);
  const logoImg = useSelector(state => state.skin.logo);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });
  const [selectedRole, setSelectedRole] = React.useState('shop');

  const handleRoleChange = event => {
    setSelectedRole(event.target.value);
  };

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  // const handleBack = () => {
  //   history.goBack();
  // };

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };
  const handleSignIn = event => {
    event.preventDefault();
    // history.push('/');
    var params = {
      username: formState.values.username,
      password: formState.values.password,
      loginFromBC: 1
    };
    userLoginAction({dispatcher: dispatcher, params: params});
  };
  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;
  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.quoteContainer}
          item
          lg={5}
        >
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Typography
                className={classes.quoteText}
                variant="h1"
              >
                Welcome to Passion Group Back Office
              </Typography>
              {/*<div className={classes.person}>*/}
              {/*  <Typography*/}
              {/*    className={classes.name}*/}
              {/*    variant="body1"*/}
              {/*  >*/}
              {/*    Takamaru Ayako*/}
              {/*  </Typography>*/}
              {/*  <Typography*/}
              {/*    className={classes.bio}*/}
              {/*    variant="body2"*/}
              {/*  >*/}
              {/*    Manager at inVision*/}
              {/*  </Typography>*/}
              {/*</div>*/}
            </div>
          </div>
        </Grid>
        <Grid
          className={classes.content}
          item
          lg={7}
          xs={12}
        >
          <div className={classes.content}>
            {/* <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </div> */}
            <div className={classes.logoContainer}>
              <img
                alt="logo"
                className={classes.logoImage}
                // src={`images/logos/${localStorage.skin ? localStorage.skin.logo : ''}`}
                src={`images/logos/${logoImg}`}
              />
            </div>
            <div className={classes.contentBody}>
              <form
                variant='outlined'
                className={classes.form}
                onSubmit={handleSignIn}
              >
                <Typography
                  className={classes.title}
                  variant="h2"
                >
                </Typography>
                <Typography
                  color="textSecondary"
                  gutterBottom
                >
                  {/* Sign in with social media */}
                </Typography>
                <Grid
                  className={classes.socialButtons}
                  container
                  spacing={2}
                >
                  {/* <Grid item>
                    <Button
                      color="primary"
                      onClick={handleSignIn}
                      size="large"
                      variant="contained"
                    >
                      <FacebookIcon className={classes.socialIcon} />
                      Login with Facebook
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={handleSignIn}
                      size="large"
                      variant="contained"
                    >
                      <GoogleIcon className={classes.socialIcon} />
                      Login with Google
                    </Button>
                  </Grid> */}
                </Grid>
                <Typography
                  align="center"
                  className={classes.sugestion}
                  color="textSecondary"
                  variant="body1"
                >
                  {/* or login with your username */}
                </Typography>
                <label className={classes.label}>{t.username}</label>
                <TextField
                  className={classes.textField}
                  error={hasError('username')}
                  fullWidth
                  helperText={
                    hasError('username') ? formState.errors.username[0] : null
                  }
                  name="username"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.username || ''}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <AccountCircle className={classes.icon}/>
                      </InputAdornment>
                    ),
                  }}
                  variant="filled"
                />
                <label className={classes.label}>{t.password}</label>
                <TextField
                  className={classes.textField}
                  error={hasError('password')}
                  fullWidth
                  helperText={
                    hasError('password') ? formState.errors.password[0] : null
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <VpnKeyRoundedIcon className={classes.keyIcon}/>
                      </InputAdornment>
                    ),
                  }}
                  name="password"
                  onChange={handleChange}
                  type="password"
                  value={formState.values.password || ''}
                  variant="filled"
                />
                {authData.errorMessage &&
                 <SnackbarContent
                  className={classes.error}
                  message={<span><ErrorIcon className={classes.messageIcon}/>{authData.errorMessage}</span>}
                 >
                 </SnackbarContent>
                }
                <Button
                  className={classes.signInButton}
                  disabled={!formState.isValid}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {t.signInNow}
                </Button>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  <Link
                    component={RouterLink}
                    to = {'#'}
                    variant="body1"
                    className={classes.forgotPassword}
                  >
                    {t.forgotPassword}?
                  </Link>
                </Typography>
              </form>
            </div>
              {/*<div className={classes.loginRole}>*/}
              {/*  <Typography*/}
              {/*    variant="body1"*/}
              {/*    className={classes.chooseRole}*/}
              {/*  >*/}
              {/*    {t.loginSeller}*/}
              {/*  </Typography>*/}
              {/*  <Typography*/}
              {/*   className={classes.chooseRole}*/}
              {/*   variant="body1"*/}
              {/*  >*/}
              {/*    {t.loginStore}*/}
              {/*  </Typography>*/}
              {/*  <div className={classes.roleIcons}>*/}
              {/*    <Radio*/}
              {/*      checked={selectedRole === 'seller'}*/}
              {/*      icon={<IconButton className={classes.roleButton}><PersonIcon className={classes.userIcon}/></IconButton>} */}
              {/*      checkedIcon={<IconButton className={classes.selectedRoleButton}><PersonIcon className={classes.userIcon}/></IconButton>} */}
              {/*      onChange={handleRoleChange}*/}
              {/*      value="seller"*/}
              {/*    />*/}
              {/*    <Radio*/}
              {/*      checked={selectedRole === 'shop'}*/}
              {/*      icon={<IconButton className={classes.roleButton}> <img src="images/shopIcon.png"/></IconButton>}*/}
              {/*      checkedIcon={<IconButton className={classes.selectedRoleButton}> <img src="images/shopIcon.png"/></IconButton>}*/}
              {/*      onChange={handleRoleChange}*/}
              {/*      value="shop"*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*</div>*/}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

// SignIn.propTypes = {
//   history: PropTypes.object
// };

export default withRouter(SignIn);
