import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import moment from "moment";
import { makeStyles } from '@material-ui/styles';
import StatementsTable from './StatementsTable';
import { fetchStatementsAction } from "../../../actions/Account";
import { Button, Card } from '@material-ui/core';
import { TablePagination } from '@material-ui/core';
import Helper from '../../../common/helper';
// const datePeriods = ['week', 'month', 'year'];

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    content: {
        marginTop: theme.spacing(2)
    },
    topFilters: {
        textAlign: "center"
    }
}));
const Statements = props => {
    const classes = useStyles();
    const authData = useSelector(state => state.authData);
    const accountId = props.match.params.id || authData.accountId;
    const [statements, setStatements] = useState([]);
    const [period, setPeriod] = useState('year');
    const selectedMonth = useSelector(state => state.month);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(rowsPerPage);
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const handlePageChange = (event, page) => {
        setPage(page);
    };
    const handleChangeRowsPerPage = event => {
        setRowsPerPage(event.target.value);
        setPage(0);
    };
    const fetchAccountStatements = async () => {
      // if( !dateFrom || !dateTo ) { return }
      if( !selectedMonth ) { return }
      const params = Helper.putDateParams(selectedMonth);
      const response = await fetchStatementsAction({
            ...params,
            accountId: accountId,
            token: authData.token,
            // dateFrom: dateFrom,
            // dateTo: dateTo,
            forNetwork: !props.match.params.id,   // Fetch for the whole account's network, if the account id is not passed in url
            limit: rowsPerPage,
            page: page +1
        });
      setCount(response.data.result.count);
      setStatements(response.data.result.movements);
    };

    const setDates = period => {
      const {dateFrom, dateTo} = getPeriodDates(period);
      setDateFrom(dateFrom);
      setDateTo(dateTo);
      setPeriod(period);
    };

    const getPeriodDates = period => {
      const currentDate = new moment().format('YYYY-MM-DD HH:mm:ss');
      return {
        dateFrom: moment().startOf(period).format('YYYY-MM-DD HH:mm:ss'),
        dateTo: currentDate
      };
    };

    useEffect(() => {
      console.log('StatementsList useEffect-1');
      setDates(period);
    }, []);

    useEffect(() => {
      console.log('StatementsList useEffect-2');
      fetchAccountStatements();
    // }, [dateFrom, dateTo, rowsPerPage, page]);
    }, [selectedMonth, rowsPerPage, page]);

    return (
        <div className={classes.root}>
            <div className={classes.content}>

              {/*<Card className={classes.topFilters}>*/}
              {/*  {*/}
              {/*    datePeriods.map(periodItem => (*/}
              {/*      <Button*/}
              {/*        key={periodItem}*/}
              {/*        onClick={() => setDates(periodItem)}*/}
              {/*        variant={period === periodItem ? "contained" : ""}*/}
              {/*        color={period === periodItem ? "primary" : "inherit"}*/}
              {/*      >{periodItem}*/}
              {/*      </Button>*/}
              {/*    ))*/}
              {/*  }*/}
              {/*</Card>*/}
                <StatementsTable rowsPerPage={rowsPerPage} page={page} statements={statements} />
                <TablePagination
                  component="div"
                  count={count}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[5, 10, 25]}
            />
            </div>
        </div>
    );
};

export default Statements;
