import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import t from '../../../../../../configs/translations';
import ENUMS from '../../../../../../configs/enums';
import Level from './Level';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    bonusContent: {
        color: theme.palette.primary.main,
        marginBottom: 20
    },
    infoText: {
        color: theme.palette.black,
        padding: 3
    },
    note: {
        color: theme.palette.primary.main,
        padding: 3,
        fontStyle: 'italic',
        marginTop: theme.spacing(1)
    }
}));

const ShopInfoDialog = props => {
    const classes = useStyles();
    const { type, data } = props;
    const authData = useSelector(state => state.authData);
    if (!type && !data && !data.details || !data.details.levels) return "";
    let isShop = authData.subTypeId === ENUMS.roleIds.shop;

    let firstElement = 0;
    let secondElement = 0;

    if (type == 'player') {
        data.details.levels.forEach(l => firstElement += l.amount);
    } else if (type == 'deposit') {
        const { length = 0 } = data.details.levels;
        firstElement = data.details.levels[length - 1].threshold;
        data.details.levels.forEach(l => secondElement += l.amount);
    }

    const getDate = moment(data.dateTo).format("YYYY-MM-DD");
    const numberOfActiveMonths = Math.abs(moment(new Date()).diff(moment(getDate).format("YYYY-MM-DD"), 'months'));

    return (
        <React.Fragment>
            <Typography variant='h4' className={classes.bonusContent}>
                {!isShop ? t[`${type}BonusModal`] : t[`${type}BonusModalShop`]}
            </Typography>
            <Typography variant='body2' className={classes.infoText}>
                {t.formatString(t[`${type}CountBonus`], firstElement, secondElement)}
            </Typography>
            <div style={{ position: 'relative' }}>
                {data.details.levels.map(item => (
                    <Level key={item.threshold} type={type} goal={item.threshold} bonus={item.amount} />
                ))}
            </div>
            <Typography variant='body2' className={classes.note}>
                {type === "player" ? t.formatString(t[`${type}Conditions`]) :
                    numberOfActiveMonths ? t.formatString(t[`${type}ConditionsMonth`], numberOfActiveMonths) : t.formatString(t[`${type}Conditions`])}
            </Typography>
        </React.Fragment >
    );
};

export default ShopInfoDialog;
