import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import clsx from 'clsx';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import t from '../../../../../../configs/translations'
import localization from 'moment/locale/it'

import {
  Typography,
  Grid,
  Paper
} from '@material-ui/core';
import BonusTypeIcon from '@material-ui/core/SvgIcon/SvgIcon';
import ACTIONS from '../../../../../../store/actionsEnum';

const useStyles = makeStyles(theme => ({
  root: {
    padding: "2%",
    // padding: theme.spacing(1),
    '& .MuiGrid-item': {
      textAlign: "center"
    }
  },
  dateText: {
    '& h4': {
      color: "#777",
      textTransform: 'capitalize'
    }
  },
  arrow: {
    fontSize: "1.8rem",
    '&.disabled': {
      color: "#aaa"
    }
  }
}));

const MonthPicker = props => {
  const { className, onSidebarOpen, ...rest } = props;
  const classes = useStyles();
  const dispatcher = useDispatch();
  const [month, setMonth] = useState(moment());
  const language = localStorage.language === 'gb' ? 'en' : 'it';
  const decreaseMonth = () => {
    setMonth(month.clone().subtract(1, 'month'));
  };
  const increaseMonth = () => {
    setMonth(month.clone().add(1, 'month'));
  };

  useEffect(() => {
    // Month is changed, so we have to request new data here
    //
    dispatcher({
      type: ACTIONS.CHANGE_SELECTED_MONTH,
      month: month
    });
  }, [month]);

  return (
    <Paper className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <ArrowLeftIcon onClick={decreaseMonth} className={classes.arrow} />
        </Grid>
        <Grid item xs={8} className={classes.dateText}>
          <Typography variant="h4">{month.locale(language).format('MMMM YYYY')}</Typography>
        </Grid>
        <Grid item xs={2}>
          {
            month.clone().add(1, 'hour') < moment() ?
            <ArrowRightIcon onClick={increaseMonth} className={classes.arrow} /> :
            <ArrowRightIcon className={clsx(classes.arrow, 'disabled')} />
          }
        </Grid>
      </Grid>
    </Paper>
  );
};

export default MonthPicker;
