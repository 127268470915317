import React from 'react';
import {Grid, Button} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
  arrow: {
    fontSize: "3.5em",
    color: "#a7a6a6"
  },
  root: {
    width: "0%",
    height: "0%",
    borderRadius: '40px'
  }
}));

const BackButton = props => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box p={2} boxShadow={2} component={Button} onClick={() => history.goBack()} className={classes.root}>
        <ArrowLeftIcon className={classes.arrow} />
    </Box>
  );
};

export default BackButton;