import mainTheme from './main';
import { colors } from '@material-ui/core';
const white = '#FFFFFF';
const black = '#000000';
const darkGreen = '#1F8E59';
const mainGreen = '#45A474'

export default {
  ...mainTheme,
  primary: {
    contrastText: white,
    dark: darkGreen,
    main: mainGreen,
  },
};