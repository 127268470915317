import mainTheme from './main';

const main = '#d84315';
const dark = "#bf360c";
const light = "#ff5722";

export default {
  ...mainTheme,
  primary: {
    dark: dark,
    main: main,
    light: light
  },
};

