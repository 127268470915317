import React, { useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { UsersToolbar, UserRoles,  UsersTable } from './components';
import { fetchNetworkAction } from "../../../actions/Account";
import ACTIONS from '../../../store/actionsEnum';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const UserList = () => {
  const classes = useStyles();
  const authDataXX = useSelector(state => state.authData);
  const [accounts, setAccounts] = useState([]);
  const dispatcher = useDispatch();

  const fetchAccountNetwork = async () => {
    try {
      dispatcher({ type: ACTIONS.DATA_FETCHING_START });
      const response = await fetchNetworkAction(authDataXX);
      setAccounts(response.data.result.accounts);
      dispatcher({ type: ACTIONS.DATA_FETCHING_END });
    } catch (error) {
      setAccounts([]);
      console.log(error)
    }
  };

  useEffect(() => {
    console.log('UserList useEFfect');
    fetchAccountNetwork();
  }, []);

  return (
    <div className={classes.root}>
      {/* <UsersToolbar /> */}
      <UserRoles />
      <div className={classes.content}>
        <UsersTable users={accounts} />
      </div>
    </div>
  );
};

export default UserList;
