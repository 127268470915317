import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import t from '../../../../../configs/translations';
import {
  Typography,
  Grid,
  Paper,
  Button,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  ListItem
} from '@material-ui/core';
import BonusTypeIcon from "@material-ui/icons/Lens";
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import ModalDialog from 'components/views/ModalDialog';
import { useSelector } from 'react-redux';
import Helper from '../../../../../common/helper';
import ENUMS from '../../../../../configs/enums';

const useStyles = makeStyles(theme => ({
  root: {
    // height: '100%',
    '& .MuiExpansionPanelSummary-root': {
      padding: "0 1%",
      margin: "1%"
    },
    '& .MuiExpansionPanelDetails-root': {
      display: "block"
    },
    '& .MuiExpansionPanelSummary-content': {
      margin: "7px 0"
    }
  },
  playerIcon: {
    fontSize: "1.2em",
    [theme.breakpoints.down('sm')]: {
      fontSize: "1em",
    },
    color: theme.palette.warning.main
  },
  depositIcon: {
    fontSize: "1.2em",
    [theme.breakpoints.down('sm')]: {
      fontSize: "1em",
    },
    color: theme.palette.info.main
  },
  contractIcon: {
    fontSize: "1.2em",
    [theme.breakpoints.down('sm')]: {
      fontSize: "1em",
    },
    color: theme.palette.error.light
  },
  levelTitle: {
    fontSize: "0.8em",
    textAlign: "center",
    [theme.breakpoints.down('sm')]: {
      fontSize: "0.5em",
    },
  },
  bonusTitle: {
    fontSize: "0.7em",
    textAlign: "center"
  },
  bonusValue: {
    fontSize: "1em",
    textAlign: "center",
    [theme.breakpoints.down('sm')]: {
      fontSize: "0.8em",
    },
  },
  levelValue: {
    backgroundColor: theme.palette.primary.main,
    textAlign: "center",
    color: theme.palette.white,
    borderRadius: "15px",
    fontSize: "1em",
    [theme.breakpoints.down('sm')]: {
      fontSize: "0.8em",
    },
  },
  infoButton: {
    padding: 0,
    minWidth: 20
  },
  nameContainer: {
    display: 'flex',
  },
  infoIcon: {
    fontSize: '16px',
    marginLeft: 3,
    color: theme.palette.text.main
  },
  bonusRow: {
    borderTop: "1px solid #ccc",
    margin: "0",
    '& .checkIcon': {
      height: 20,
      width: 20,
      color: theme.palette.success.light
    },
    '& .levelBonus': {
      color: theme.palette.success.light,
      fontWeight: 700
    }
  }
}));

const Bonus = props => {
  const { data, type, config, currency } = props;
  const classes = useStyles();
  const roleId = useSelector(state => state.authData.subTypeId);
  let isShop = roleId === ENUMS.roleIds.shop;
  const isDistributor = roleId === ENUMS.roleIds.distributor;
  const isConsultant = roleId === ENUMS.roleIds.consultant;
  const selectedAccount = useSelector(state => state.selectedAccount);
  if ((isConsultant || isDistributor) && selectedAccount.subTypeId) {
    isShop = selectedAccount.subTypeId === ENUMS.roleIds.shop
  }
  let bonusAmount = 0;
  let completedItems = [];
  let achievedLevel = 0;
  if (!!data) {
    completedItems = data.bonuses ? data.bonuses : [];
    achievedLevel = data.totalBonus ? data.totalBonus.level : 0;
    if (type == 'contract') {
      achievedLevel = data.totalBonus ? data.totalBonus.count : 0;
    }
    bonusAmount = data.totalBonus ? data.totalBonus.amount : 0;
  }

  const [isInfoDialogOpened, setIsInfoDialogOpened] = React.useState(false);
  const handleClose = () => {
    setIsInfoDialogOpened(false);
  };

  return (
    <ExpansionPanel className={classes.root}>
      <ExpansionPanelSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {!!config && isInfoDialogOpened &&
          <ModalDialog
            open={isInfoDialogOpened}
            onClose={handleClose}
            type={`info`}
            subType={type}
            data={config} />
        }
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={2}>
            <BonusTypeIcon className={classes[`${type}Icon`]} />
          </Grid>
          <Grid item xs={5}>
            <Typography variant="h6" className={classes.nameContainer}>
              {t[`${type}Bonus`]}
              <Button className={classes.infoButton} onClick={() => setIsInfoDialogOpened(true)}>
                <InfoIcon className={classes.infoIcon} />
              </Button>
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <div>
              <Typography className={classes.levelTitle}>{t.completed}</Typography>
              <Typography className={classes.levelValue}>{String(achievedLevel || 0)}</Typography>
            </div>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.bonusTitle}>{t.bonus}</Typography>
            <Typography className={classes.bonusValue}>{Helper.formatMoney({ amount: bonusAmount, currency: currency })}</Typography>
          </Grid>
        </Grid>
      </ExpansionPanelSummary>
      {!!completedItems && completedItems.length > 0 &&
        <ExpansionPanelDetails>
          {completedItems.map(lvl => lvl.achievement < lvl.threshold ? '' : (<Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={2}
            className={classes.bonusRow}
            key={isShop ? type + lvl.level : type + lvl.account.username}
          >
            <Grid item xs={isShop ? 8 : 5}>
              <Typography type="h6">{isShop ? `${t.step} ${lvl.level}` : lvl.account.username}</Typography>
            </Grid>
            <Grid item xs={isShop ? 2 : 5}>
              <ListItem>
                {!isShop && type !== 'contract' && `${t.step} ${lvl.level}`}
                <CheckCircleRoundedIcon className={"checkIcon"} />
              </ListItem>
            </Grid>
            <Grid item xs={2}>
              <Typography type="h6" className={"levelBonus"}>{Helper.formatMoney(lvl.bonus)}</Typography>
            </Grid>
          </Grid>
          ))}
        </ExpansionPanelDetails>
      }
    </ExpansionPanel>
  );
};

// Bonus.propTypes = {
//   className: PropTypes.string
// };

export default Bonus;
