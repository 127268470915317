import ACTIONS from '../actionsEnum';
import moment from 'moment';

export const month = (state = moment().format('M'), action) => {
  switch (action.type) {
    case ACTIONS.CHANGE_SELECTED_MONTH:
      return action.month;
    default:
      return state
  }
};
