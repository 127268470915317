import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/styles';
import ENUMS from '../../../configs/enums';
import t from '../../../configs/translations';
import ChildrenTable from './ChildrenTable';
import { fetchChildrenByRoleAction } from "../../../actions/Account";
import { TablePagination, Typography, Divider } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    },
    content: {
        marginTop: theme.spacing(2)
    },
    tableTitle: {
      textAlign: "center",
      textTransform: "capitalize",
      marginBottom: "2%",
      color: theme.palette.primary.main,
      fontSize: "1.1em",
      marginTop: "5%"
    }
}))

const Children = props => {
    const classes = useStyles();
    const authData = useSelector(state => state.authData);
    const pageType = props.match.params.page;
    const roleType = props.match.params.roleType;
    const selectedAccount = useSelector(state => state.selectedAccount);
    const accountId = selectedAccount.id || props.match.params.accountId  || authData.accountId;
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const [accounts, setAccounts] = useState([]);
    const [count, setCount] = useState(rowsPerPage);

    const handlePageChange = (event, page) => {
        setPage(page);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(event.target.value);
        setPage(0);
    };

    const fetchChildrenByRole = async () => {
      try {
        const response = await fetchChildrenByRoleAction({
          accountId: accountId,
          subTypes: !!roleType ? `[${ENUMS.roleIds[roleType]}]` : [],
          token: authData.token,
          limit: rowsPerPage,
          page: page + 1
        },
        );
        setCount(response.data.result.count);
        setAccounts(response.data.result.accounts);
      } catch (err) {
        console.log(err);
        setCount(rowsPerPage);
        setAccounts([]);
      }
    };

    useEffect(() => {
      console.log('PlayerList useEFfect');
      fetchChildrenByRole();
    }, [rowsPerPage, page]);

    return (
      <div className={classes.root}>
        <div className={classes.content}>
          {
            (pageType === 'earnings') &&
            <div>
              <Typography variant="h3" className={classes.tableTitle}>{t.monthlyProfit}</Typography>
              <Divider/>
            </div>
          }
          <ChildrenTable rowsPerPage={rowsPerPage} page={page} pageType={pageType} accounts={accounts} roleType={!!roleType ? ENUMS.roleIds[roleType] : ''} />
          <TablePagination
            component="div"
            count={count}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </div>
      </div>
    );
};

export default Children;
