import React from 'react';
import gitVersion from '../../../../../../_git_commit';
import { makeStyles } from '@material-ui/styles';
import { Typography, colors } from '@material-ui/core';
import t from '../../../../../../configs/translations';

const useStyles = makeStyles(() => ({
  version: {
    position: 'fixed',
    bottom: '2%'
  }
}));

const GitVersion = () => {
  const classes = useStyles();

  return (
      <div className={classes.version}>
        <Typography align="left" variant="h6" >
          {`${t.version}: ${gitVersion.logMessage ? gitVersion.logMessage : 'xxx'}`}
        </Typography>
      </div>
  );
};

export default GitVersion;
