import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/styles';
import BonusList from './components/List';
import { fetchChildrenByRoleAction } from "../../../../actions/Account";
import ENUMS from '../../../../configs/enums';
import { Typography } from '@material-ui/core';
import { TablePagination } from '@material-ui/core';
import t from '../../../../configs/translations'
import ACTIONS from '../../../../store/actionsEnum';
import { useDispatch } from 'react-redux';
import Helper from '../../../../common/helper';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0
  },
  tableTitle: {
    textAlign: "center",
    textTransform: "capitalize",
    marginBottom: "2%",
    color: theme.palette.primary.main,
    fontSize: "1.1em",
    marginTop: "5%"
  },
  noRecords: {
    color: theme.palette.primary.main,
    textAlign: 'center'
  }
}));

const BonusTab = props => {
  const classes = useStyles();
  const authData = useSelector(state => state.authData);
  const selectedMonth = useSelector(state => state.month);
  const selectedAccount = useSelector(state => state.selectedAccount);
  const accountId = selectedAccount.id || props.match.params.accountId  || authData.accountId;
  const [accounts, setAccounts] = useState([]);
  const dispatcher = useDispatch();
  const [rowsPerPage, setRowsPerPage] = useState(2000);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(rowsPerPage);

  const fetchChildrenByRole = async () => {
    try {
      dispatcher({ type: ACTIONS.DATA_FETCHING_START });
      const params = Helper.putDateParams(selectedMonth);
      const response = await fetchChildrenByRoleAction({
        ...params,
        accountId: accountId,
        subTypes: `[${ENUMS.roleIds.shop}]`,
        token: authData.token,
        limit: rowsPerPage,
        page: page + 1
      },
      );
      setCount(response.data.result.count);
      setAccounts(response.data.result.accounts);
      dispatcher({ type: ACTIONS.DATA_FETCHING_END });
    } catch (err) {
      console.log(err);
      setCount(rowsPerPage);
      setAccounts([]);
    }
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if( !!selectedMonth && selectedMonth._isAMomentObject) {
      setPage(0);
      fetchChildrenByRole();
    }
  }, [selectedMonth]);

  useEffect(() => {
    if( !!selectedMonth && selectedMonth._isAMomentObject) {
      fetchChildrenByRole();
    }
  }, [rowsPerPage, page]);

  return (
    <div className={classes.root}>
      {accounts.length ?
        <React.Fragment>
          <BonusList accounts={accounts} />
          {/* <TablePagination
            component="div"
            count={count}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          /> */}
        </React.Fragment>
        : <Typography variant="h3" className={classes.noRecords}>{t.noRecords}</Typography>
      }
    </div>
  );
};

export default BonusTab;
