import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Doughnut } from 'react-chartjs-2';
import Helper from '../../../../../common/helper';
import moment from 'moment';
import t from '../../../../../configs/translations';
import InfoIcon from '@material-ui/icons/Info';

import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Divider,
  Typography, Button, ExpansionPanelSummary
} from '@material-ui/core';
import ModalDialog from 'components/views/ModalDialog';
import RefreshIcon from '@material-ui/core/SvgIcon/SvgIcon';


const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    backgroundColor: "#fff",
    padding: "1vh 0"
  },
  header: {
    textAlign: "center",
    marginTop: "2%",
    marginBottom: "5%",
    color: theme.palette.primary.main
  },
  chartContainer: {
    position: 'relative',
    height: 200,
    padding: "3%",
    backgroundImage:'url(/images/commissionChartBg.png)',
    backgroundRepeat: "no-repeat",
    backgroundSize: "66%",
    backgroundPosition: "66% 0%"
  },
  chartInnerText: {
    width: "100%",
    height: "100%",
    position: 'absolute',
    top: 0,
    left: "1%",
    padding: "16% 0",
    textAlign: "center"
  },
  infoButton: {
    position: "absolute",
    right: "7%",
    color: theme.palette.text.main
  },
  monthDay: {
    color: "#777",
    marginBottom: "1%"
  },
  fee: {
    color: theme.palette.primary.main
  }
}));

const CommissionChart = props => {
  const { className, data, currency, config, ...rest } = props;
  const classes = useStyles();
  const [monthDay, setMonthDay] = useState(moment());
  const theme = useTheme();
  const percentage = config && config.details ? config.details.percentage : 0;

  const chartData = {
    datasets: [
      {
        data: [
          monthDay ? monthDay.date() : 0,
          monthDay ? moment().daysInMonth() - monthDay.date() : 0,
        ],
        backgroundColor: [
          theme.palette.primary.main,
          "#f0f1f1"
        ],
        borderWidth: 1,
        borderColor: "#f0f1f1",
        hoverBorderColor: "#f0f1f1"
      }
    ],
    labels: ['Days Passed', 'Remained']
  };



  const options = {
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: true,
    cutoutPercentage: 94,
    layout: { padding: 0 },
    showTooltips: true,
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary
    }
  };

  const [isInfoDialogOpened, setIsInfoDialogOpened] = React.useState(false);
  const handleClose = () => {
    setIsInfoDialogOpened(false);
  };

  return (
    <div className={classes.root}>

      { isInfoDialogOpened &&
        <ModalDialog data={percentage} open={isInfoDialogOpened} onClose={handleClose} type={`info`} subType={'monthly'} />
      }

      <Button  className={classes.infoButton} onClick={()=>setIsInfoDialogOpened(true)}>
        <InfoIcon className={classes.infoIcon}/>
      </Button>
      <Typography variant="h3" className={classes.header}>{t.monthlyProfit}</Typography>
      <div className={classes.chartContainer}>
        <Doughnut
          data={chartData}
          options={options}
        />
        <div className={classes.chartInnerText}>
          { data &&
          <div>
            <Typography variant="h5" className={classes.monthDay}> {monthDay.format('DD MMMM')} </Typography>
            <Typography variant="h2" className={classes.fee}> { Helper.formatMoney( {amount: data.fee, currency: currency}) } </Typography>
          </div>
          }
        </div>
      </div>
    </div>

  );
};

CommissionChart.propTypes = {
  className: PropTypes.string
};

export default CommissionChart;
