import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import storeFactory from './store';

import * as serviceWorker from './serviceWorker';
import App from './App';

const store = storeFactory();
window.React = React;
window.store = store;

// ReactDOM.render(<App />, document.getElementById('root'));

ReactDOM.render(
    <Provider store={store} >
        <App store={store} />
    </Provider>
    , document.getElementById('root'));

serviceWorker.unregister();
