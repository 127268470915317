import { createMuiTheme } from '@material-ui/core';

import * as palettes from './palettes';
import typography from './typography';
import overrides from './overrides';

const baseConfig = {
  palette: palettes["mainPalette"],
  typography,
  overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1100
  }
};

export const king = createMuiTheme({
  ...baseConfig,
  palette: palettes["king"]
});

export const vittoria = createMuiTheme({
  ...baseConfig,
  palette: palettes["vittoria"]
});

export const blu = createMuiTheme({
  ...baseConfig,
  palette: palettes["blu"]
});

export const looking = createMuiTheme({
  ...baseConfig,
  palette: palettes["looking"]
});

export const konsulting = createMuiTheme({
  ...baseConfig,
  palette: palettes["konsulting"]
});

export const consulting = createMuiTheme({
  ...baseConfig,
  palette: palettes["consulting"]
});

export const passion = createMuiTheme({
  ...baseConfig,
  palette: palettes["passion"]
});

export const service = createMuiTheme({
  ...baseConfig,
  palette: palettes["service"]
});

export const winner = createMuiTheme({
  ...baseConfig,
  palette: palettes["winner"]
});

export const torinogiochi = createMuiTheme({
  ...baseConfig,
  palette: palettes["torinogiochi"]
});

export const perlasolution = createMuiTheme({
  ...baseConfig,
  palette: palettes["perlasolution"]
});

export const ngconsulenze = createMuiTheme({
  ...baseConfig,
  palette: palettes["ngconsulenze"]
});

export const automatika = createMuiTheme({
  ...baseConfig,
  palette: palettes["automatika"]
});
