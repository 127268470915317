import React from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import t from '../../../../../configs/translations';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Helper from '../../../../../common/helper';
import { LinearProgress, Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  playerIcon: {
    fontSize: '1em',
    color: theme.palette.warning.main
  },
  depositIcon: {
    fontSize: '1em',
    color: theme.palette.info.main
  },
  playerBonus: {
    display: 'inline-block',
    width: '40%',
    marginRight: 10,
    borderBottom: `1px solid ${theme.palette.divider}`,
    '& .MuiLinearProgress-colorPrimary': {
      backgroundColor: theme.palette.warning.lighter,
      height: 10
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: theme.palette.warning.main
    }
  },
  depositBonus: {
    display: 'inline-block',
    width: '40%',
    marginRight: 10,
    borderBottom: `1px solid ${theme.palette.divider}`,
    '& .MuiLinearProgress-colorPrimary': {
      backgroundColor: theme.palette.info.lighter ,
      height: 10
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: theme.palette.info.main
    }
  },
  bottomBorder: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginTop: 5,
    '& .MuiLinearProgress-root': {
      height: 8
    }
  },
  step: {
    color: theme.palette.primary.main,
    fontSize: '0.8em'
  },
  bonus: {
    color: theme.palette.text.lighter,
    fontSize: '1em'
  },
}));

const LevelProgress = props => {
  const { type, bonusData, currency } = props;
  const classes = useStyles();

  let currentObjective = {};
  if(!!bonusData && !!bonusData.bonuses ) {
    currentObjective = bonusData.bonuses.find(obj => obj.achievement < obj.threshold) || {};
    if (!Object.keys(currentObjective).length){
      let length = bonusData.bonuses.length;
      currentObjective = {};
      if(length > 0) {
        currentObjective = bonusData.bonuses[length - 1];
      }
    }
  }

  const getProgressValue = () => {
    if (currentObjective && currentObjective.achievement && currentObjective.threshold) {
      let progressData = currentObjective.threshold - currentObjective.achievement;
      if (progressData < 0) {
        return 100;
      }
      return currentObjective.achievement / currentObjective.threshold * 100;
    }
    return 0;
  }

  return(
    <React.Fragment>
      <Grid item  xs={6} md={3} lg={3} sm={3} className={classes.bottomBorder}>
        <div>
          <LinearProgress
            variant="determinate"
            className={classes[`${type}Bonus`]}
            value={getProgressValue()}
          />
          {type === 'deposit' && !!currentObjective.bonus ?
            <span style={{ fontSize: '11px' }}>
              {Helper.formatMoney({ amount: currentObjective.achievement, currency: currency })}/
              {Helper.formatMoney({ amount: currentObjective.threshold, currency: currency })}
            </span>
            :
            <span style={{ fontSize: '11px' }}>
              {currentObjective.achievement} / {currentObjective.threshold}</span>
          }
        </div>
        <div>
          <FiberManualRecordIcon className={classes[`${type}Icon`]}/>
          <span style={{fontSize: 12}}>{t.bonusTypes[`${type}`]}</span> / <span className={classes.step}>{t.step} {currentObjective.level}</span>
        </div>
      </Grid>
      <Grid item xs={2}  md={1} lg={1} sm={1} className={classes.bottomBorder}>
        <span>{t.bonus}</span>
        { !!currentObjective.bonus &&
          <p className={classes.bonus}>
            { Helper.formatMoney(currentObjective.bonus) }
          </p>
        }
      </Grid>
    </React.Fragment>
  )
};

LevelProgress.propTypes = {
  type: PropTypes.string,
  objectives: PropTypes.array
};

export default LevelProgress;

