/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { List, ListItem, Button, colors } from '@material-ui/core';
import { userLogoutAction } from "../../../../../../actions/Account";
import {  useDispatch } from "react-redux";
import InputIcon from '@material-ui/icons/Input';
import t from '../../../../../../configs/translations';
import { useSelector } from 'react-redux';
import ENUMS from '../../../../../../configs/enums';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  invisible: {
    display: 'none'
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette.primary.main,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));

const SidebarNav = props => {
  const { pages, className, onClose, ...rest } = props;
  const classes = useStyles();
  const dispatchXX = useDispatch();
  const authData = useSelector(state => state.authData);
  const isShop = authData.subTypeId === ENUMS.roleIds.shop;
  const isDistributor = authData.subTypeId === ENUMS.roleIds.distributor;
  const isConsultant = authData.subTypeId === ENUMS.roleIds.consultant;

  const logoutUser = () => {
    userLogoutAction(dispatchXX);
  }
  return (
    <List
      {...rest}
      className={clsx(classes.root, className)}
    >
      {pages.map(page => (
        <ListItem
          className={clsx({
            [classes.invisible]: isShop && page.title === `${t.network}` || (!isShop && page.title === `${t.users}`) || ((isDistributor || isConsultant) && page.title === `${t.dashboard}`),
            [classes.item]: true
          })}
          disableGutters
          key={page.title}
        >
          <Button
            activeClassName={classes.active}
            className={classes.button}
            component={CustomRouterLink}
            to={page.href}
            onClick={onClose}
          >
            <div className={classes.icon}>{page.icon}</div>
            {page.title}
          </Button>
        </ListItem>
      ))}
      <ListItem
        className={classes.item}
        disableGutters
      >
        <Button
          className={classes.button}
          color="inherit"
          onClick={logoutUser}
        >
          <div className={classes.icon}><InputIcon /></div>
          {t.signOut}
        </Button>
      </ListItem>
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
};

export default SidebarNav;
