import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import EditIcon from '@material-ui/icons/Edit';
import { Link as RouterLink } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  Typography,
  IconButton,
  Link
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {},
  profileDetail: {
    display: "inline-block",
    marginRight: "10px"
  }
}));

const Details = props => {
  const { className, account, ...rest } = props;
  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete="off"
        noValidate
      >
        <CardHeader
          // subheader="The information can be edited"
          action={
            <Link
              component={RouterLink}
              to={`/account/edit/${account._id}`}
              variant="h6"
            >
              <IconButton size="small">
                <EditIcon />
              </IconButton>
            </Link>
          }
          title="Personal Details"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Typography className={classes.profileDetail} variant="h6">Username:</Typography>
              <Typography className={classes.profileDetail} variant="body2">{account.username}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className={classes.profileDetail} variant="h6">Nickname:</Typography>
              <Typography className={classes.profileDetail} variant="body2">{account.nickname}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className={classes.profileDetail} variant="h6">Email:</Typography>
              <Typography className={classes.profileDetail} variant="body2">{account.personalInfo ? account.personalInfo.email : ''}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className={classes.profileDetail} variant="h6">Status:</Typography>
              <Typography className={classes.profileDetail} variant="body2">{account.enabled}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className={classes.profileDetail} variant="h6">Country:</Typography>
              <Typography className={classes.profileDetail} variant="body2">{account.countryCode}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className={classes.profileDetail} variant="h6">Created on:</Typography>
              <Typography className={classes.profileDetail} variant="body2">{moment.utc(account.dateCreated).format('MM-DD-YYYY')}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className={classes.profileDetail} variant="h6">Address:</Typography>
              <Typography className={classes.profileDetail} variant="body2">{account.personalInfo ? account.personalInfo.address : ''}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className={classes.profileDetail} variant="h6">Phone Number:</Typography>
              <Typography className={classes.profileDetail} variant="body2">{account.personalInfo ? account.personalInfo.phoneNumber : ''}</Typography>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
      </form>
    </Card>
  );
};

Details.propTypes = {
  className: PropTypes.string
};

export default Details;
