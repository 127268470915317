import mainTheme from './main';
import { colors } from '@material-ui/core';

const main = '#0e7e00';
const dark = "#36883d";
const light = "#4fae3a";

export default {
  ...mainTheme,
  primary: {
    dark: dark,
    main: main,
    light: light
  },
};