import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/styles';

import PlayersTable from './PlayersTable';
import { fetchPlayersAction } from "../../../actions/Account";
import { TablePagination, Paper } from '@material-ui/core';
import Helper from '../../../common/helper';
import ENUMS from '../../../configs/enums';
import t from '../../../configs/translations';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    },
    content: {
        marginTop: theme.spacing(2)
    },
    total: {
        padding: "1%",
        display: 'flex',
        alignItems: 'center',
        fontSize: '0.7em',
    },
    deposits: {
        padding: "0 5%",
    },
}));

const Players = props => {
    const classes = useStyles();
    const authData = useSelector(state => state.authData);
    const accountId = props.match.params.id  || authData.accountId;
    const selectedMonth = useSelector(state => state.month);
    const [rowsPerPage, setRowsPerPage] = useState(2000);
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState(-1);
    const [players, setPlayers] = useState([]);
    const [deposits, setDeposits] = useState({amount: 0, currency: 'EUR'});
    const [count, setCount] = useState(0);
    const roleId = useSelector(state => state.authData.subTypeId);
    const isShop = roleId === ENUMS.roleIds.shop;

    const handlePageChange = (event, page) => {
        setPage(page);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(event.target.value);
        setPage(0);
    };

    const sortAccountPlayers = (array, sortBy) => {
        let arrayClone = array.slice(0);
        arrayClone.sort(function(a, b) {
            if (a[sortBy] > b[sortBy]) {
                return -1;
            }
            if (a[sortBy] < b[sortBy]) {
                return 1;
            }

            return 0;
        });

        return (order == 1) ? arrayClone : arrayClone.reverse();
    };

    const handleChangeOrder = event => {
        setOrder(order * (-1));
        setPlayers(sortAccountPlayers(players, 'totalDeposit'));
    };

    const fetchAccountPlayers = async () => {
      const params = Helper.putDateParams(selectedMonth);
      const response = await fetchPlayersAction({
            ...params,
            accountId: accountId,
            token: authData.token,
            active: isShop,
            forNetwork: !props.match.params.id,   // Fetch for the whole account's network, if the account id is not passed in url
            parentId: authData.accountId,
            limit: rowsPerPage,
            page: page + 1
        },
        );
        let data = response.data.result;
        setCount(data.count);
        setPlayers(sortAccountPlayers(data.accounts, 'totalDeposit'));
        setDeposits({amount: data.totalDeposit, currency: data.currency });
    };

    useEffect(() => {
        if (!!selectedMonth && selectedMonth._isAMomentObject) {
            console.log('PlayerList useEFfect');
            fetchAccountPlayers();
        }
    }, [rowsPerPage, page, selectedMonth]);

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <PlayersTable order={order} onChangeOrder={handleChangeOrder} rowsPerPage={rowsPerPage} page={page} players={players} />
                <Paper className={classes.total} >
                    <div className={classes.users}>
                        <span>{`${t.totalUsers}: `}</span>
                        <span>{count}</span>
                    </div>
                    <div className={classes.deposits}>
                        <span>{`${t.totalDeposits}: `}</span>
                        <span>{Helper.formatMoney(deposits)}</span>
                    </div>
                </Paper>
                {/* {count > 2000 && <TablePagination
                    component="div"
                    count={count}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[1500, 2000]}
                />} */}
            </div>
        </div>
    );
};

export default Players;
