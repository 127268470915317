module.exports = {
  accountTypes: {
    0: "root",
    1: "player",
    2: "affiliate",
    3: "internalSkin",
    6: "admin",
    7: "subAccount",
    8: "security",
    9: "union",
    10: "externalSkin",
    16: "masterSkin"
  },
  accountTypeIds: {
    player: 1,
    affiliate: 2,
    skinInt: 3,
    admin: 6,
    subAccount: 7,
    security: 8,
    skinExt: 10,
    masterSkin: 16
  },
  permissions: {
    1: "admin",
    2: "skin",
    3: "affiliate",
    4: "player",
    5: "masterSkin",
  },
  roleNames: {
    1: "company",
    2: "consultant",
    3: "distributor",
    4: "seller",
    5: "salesman",
    6: "shop"
  },
  roleIds: {
    company: 1,
    consultant: 2,
    distributor: 3,
    seller: 4,
    salesman: 5,
    shop: 6
  },
  currencySymbols: {
    USD: "$",
    EUR: "€",
    TRY: "₺"
  },
  bonusTypes: {
    commission: 'pg-commission',
    deposit: "pg-deposit",
    activePlayers: "pg-activePlayers",
    immediateEarning: "pg-immediateEarning"
  },
};
