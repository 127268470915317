import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/styles';
import { bonusTypes } from '../../../configs/enums';

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Link,
  TextField,
  Switch,
  Typography, Button, CardActions, TableCell
} from '@material-ui/core';
import { fetchBonusDataAction, getAccountAction } from '../../../actions/Account';

import { AccountProfile, AccountDetails } from './components';
import { Bonus, Commission } from '../Dashboard/components';
// import clsx from 'clsx';
// import RefreshIcon from '@material-ui/core/SvgIcon/SvgIcon';
import EditIcon from '@material-ui/icons/Edit';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  configCard: {
    height: "100%"
  },
  controlSwitch: {
    color: theme.palette.primary.main
  },
  configsContent: {
    paddingBottom: 0
  },
  submitChanges: {
    textAlign: "center",
    marginTop: "3%"
  },
  configSet: {
    marginBottom: "3%"
  },
  controlButton: {
    minWidth: "100px",
    marginRight: "2%"
  },
  commissionPercent: {
    width: "55%"
  },
  percentSymbol: {
    display: "inline-block",
    margin: "5%",
    fontSize: "1.5em"
  }
}));

const EditBonus = props => {
  const classes = useStyles();
  const authData = useSelector(state => state.authData);
  const accountId = props.match.params.id  || authData.accountId;
  const [bonusData, setBonusData] = useState({});
  const [bonusConfigs, setBonusConfigs] = useState({
    commissionPercent: 3,
    playersBonus: true,
    depositsBonus: true
  });

  const fetchBonusData = async () => {
    try {
      const response = await fetchBonusDataAction({ token: authData.token });
      setBonusData(response.data.result.bonuses);
    } catch (err) {
      console.log(err);
      setBonusData({});
    }
  };

  useEffect(() => {
    console.log('Account useEFfect');
    fetchBonusData();
  }, []);

  const handleChange = event => {
    setBonusConfigs({
      ...bonusConfigs,
      [event.target.name]: event.target.value
    });
  };

  const handleSwitchChange = event => {
    setBonusConfigs({
      ...bonusConfigs,
      [event.target.name]: event.target.checked
    });
  };
  return (
    <div className={classes.root}>
      <Card className={classes.configSet}>
        <CardHeader
          // subheader="Control each type of bonuses individually"
          // title="Edit commissions config"
          title={<Typography variant="h3">Edit commissions config</Typography>}
        />
        {/*<Typography variant="h2">Edit commissions config</Typography>*/}
        <Divider />
        <CardContent>
          <Grid container spacing={4}>
            <Grid item md={4} xs={12}>
              <Card className={classes.configCard}>
                <CardHeader
                  title="Commission Percent"
                />
                <CardContent>
                  <div>
                    <TextField
                      label="Reseller Commission"
                      margin="dense"
                      name="commissionPercent"
                      onChange={handleChange}
                      type="number"
                      value={bonusConfigs.commissionPercent || ''}
                      variant="outlined"
                      className={classes.commissionPercent}
                    />
                    <span className={classes.percentSymbol}>%</span>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={4} xs={12}>
              <Card className={classes.configCard}>
                <CardHeader
                  title="Players' Bonus"
                />
                <CardContent>
                    <Switch checked={!!bonusConfigs.playersBonus} name="playersBonus" onChange={handleSwitchChange} />
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={4} xs={12}>
              <Card className={classes.configCard}>
                <CardHeader
                  title="Deposits' Bonus"
                />
                <CardContent className={classes.configsContent}>
                  {/*<Grid container spacing={1}>*/}
                  {/*  <Grid item><Typography>Disable</Typography></Grid>*/}
                  {/*  <Grid item>*/}
                      <Switch className={classes.controlSwitch} checked={!!bonusConfigs.depositsBonus} name="depositsBonus" onChange={handleSwitchChange} />
                  {/*  </Grid>*/}
                  {/*  <Grid item><Typography>Enable</Typography></Grid>*/}
                  {/*</Grid>*/}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          {/*<Divider />*/}
          <div className={classes.submitChanges}>
            <Button
              color="primary"
              variant="contained"
              className={classes.controlButton}
            >
              Save
            </Button>
            <Link
              component={RouterLink}
              to={`/account/view/${accountId}`}
            >
            <Button
              color="primary"
              variant="contained"
              className={classes.controlButton}
            >
              Cancel
            </Button>
            </Link>
          </div>
        </CardContent>
      </Card>

      <Grid container spacing={4}>
        <Grid item md={4} xs={12}>
          <Commission data={bonusData[bonusTypes.commission] || {}} />
        </Grid>

        { bonusConfigs.playersBonus &&
        <Grid item md={4} xs={12}>
          <Bonus type={"player"} data={bonusData[bonusTypes.activePlayers] || {}}/>
        </Grid>
        }
        {bonusConfigs.depositsBonus &&
        <Grid item md={4} xs={12}>
          <Bonus type={"deposit"} data={bonusData[bonusTypes.deposit] || {}}/>
        </Grid>
        }
      </Grid>
    </div>
  );
};

export default EditBonus;
