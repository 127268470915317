import axios from 'axios';
import config from '../configs/configs';
import { skins } from '../configs/skins';
import ACTIONS from '../store/actionsEnum'
import ENUMS from '../configs/enums';
import Helper from '../common/helper';
import * as queryParser from 'query-string';

let hierarchyId = 0;
let baseUrl = config.apiBaseUrl;
let headers = {
    headers:
    {
        'Content-Type': 'application/json'
    }
};

export const userLoginAction = ({ dispatcher, params }) => {
  dispatcher({
    type: ACTIONS.START_LOGIN
  });
  if(!Number.isInteger(hierarchyId)) {
    dispatcher({
      type:  ACTIONS.LOGIN_FAILURE,
      authData: {errorMessage: "Failed to login. Please contact your administrator!!!"}
    });
    return
  }
  params.hierarchyId = hierarchyId;
  axios.post(baseUrl + '/authenticate', params)
    .then(response => {
      let currentDate = new Date();
      let curTimeWithSeconds = parseInt(currentDate.getTime()/1000);
      let exp = curTimeWithSeconds + response.data.result.exp;
      var authData = {
        accountId: response.data.result.id,
        username: response.data.result.username,
        token: response.data.result.token,
        typeId: response.data.result.masterTypeId ? response.data.result.masterTypeId : response.data.result.typeId,
        subTypeId: response.data.result.subTypeId,
        wallets: response.data.result.wallets,
        expiration: exp
      };

      dispatcher({
        type: ACTIONS.LOGIN_SUCCESS,
        authData: authData
      });
      console.log('AuthData: ', authData);
      localStorage.authenticationData = JSON.stringify(authData);

      // Request daily bonuses
      //
      getDailyBonusAction(dispatcher, {token: authData.token});
    })
    .catch(err => {
      console.error(err);
      dispatcher({
        type:  ACTIONS.LOGIN_FAILURE,
        authData: {errorMessage: err.response.data.err.message}
      });
    });
}

export const  createAccount = ({ dispatcher, params }) => {
  let storedAuth = JSON.parse(localStorage.authenticationData);
  const url = baseUrl + '/account';
  headers.headers['x-access-token'] = storedAuth.token;
  let Params = {
    username: params.userName,
    password: params.password,
    firstname: params.firstName,
    lastname: params.lastName,
    profit: params.profit,
    personalInfo: {
      email: params.email
    },
    country: params.country,
    typeId: ENUMS.accountTypeIds.affiliate,
    subTypeId: params.role,
    parentId: storedAuth.accountId,
    products: [[5]],
    currency: [(storedAuth.wallets[0].currency)],
    altCurrencyCode: 0,
    permission: 3,
  }
  return axios.post(url, Params, headers)
  .then( () => {
    dispatcher({
      type: ACTIONS.UPDATE_SUCCESS,
      authData: storedAuth
    });
  })
  .catch(
    err=>{
      console.log('before', storedAuth);
      storedAuth.errorMessage = err.response.data.err.message;
      console.log('then', storedAuth)
      dispatcher({
        type: ACTIONS.UPDATE_FAILURE,
        authData: storedAuth
        });
    }
  )
}
export const authenticationCheck = (dispatcher) => {
    let storedAuth = JSON.parse(localStorage.authenticationData);
    let currentDate = new Date();
    let curTimeWithSeconds = parseInt(currentDate.getTime()/1000);
    if(storedAuth.expiration <= curTimeWithSeconds) {
      userLogoutAction(dispatcher);
    }
    else {
      dispatcher({
          type: ACTIONS.LOGIN_SUCCESS,
          authData: storedAuth
      });
    }
}

export const userLogoutAction = (dispatcher) => {
    delete localStorage.authenticationData;
    dispatcher({
        type: ACTIONS.USER_LOGOUT,
        authData: {}
    });
    dispatcher({
        type: ACTIONS.UNSET_ACCOUNT,
    });
}

export const fetchNetworkAction = params => {
  headers.headers['x-access-token'] = params.token;
  const url = baseUrl + '/account/' + params.accountId + '/networkTree?isPassionGroup=true';
  return axios.get(url, headers);
}

export const getAccountAction = params => {
    headers.headers['x-access-token'] = params.token;
    const url = baseUrl + '/account/' + params.accountId;
    return axios.get(url, headers);
}

export const getDailyBonusAction = (dispatcher, params) => {
  headers.headers['x-access-token'] = params.token;
  const url = baseUrl + '/dailyBonus';
  return axios.get(url, headers)
  .then( response => {
    dispatcher({
      type: ACTIONS.DAILY_BONUS_RECEIVED,
      unlockedBonuses: response.data.result
    });
  })
}

export const updateAccountDetails = ({params, accountId, token, dispatcher}) => {
  headers.headers['x-access-token'] = token;
  const url = baseUrl + '/account/'+ accountId;
  let storedAuth = JSON.parse(localStorage.authenticationData);
  return axios.patch(url, {
    firstname: params.firstName,
    lastname: params.lastName,
    city: params.city,
    email: params.email,
    phoneNumber: params.phone,
    country: params.country
  }, headers)
  .then(
    () => {
      var authData = {
        accountId: storedAuth.accountId,
        username: storedAuth.username,
        token: storedAuth.token,
        typeId: storedAuth.masterTypeId ? storedAuth.masterTypeId : storedAuth.typeId,
        wallets: storedAuth.wallets,
        expiration: storedAuth.expiration
      };
      dispatcher({
        type: ACTIONS.UPDATE_SUCCESS,
        authData: authData
       });
    })
  .catch(
    err => {
      storedAuth.error = err.response.data.err.message;
      dispatcher({
        type: ACTIONS.UPDATE_FAILURE,
        authData: storedAuth
       });
    });
}

export const updatePassword = ({username, password, newpass, accountId, token, dispatcher}) => {
  let storedAuth = JSON.parse(localStorage.authenticationData);
  let params = {username: username, password: password, loginFromBC: 1}
  headers.headers['x-access-token'] = token;
  return axios.post(baseUrl + '/checkPassword', params, headers).then(
    () => {
    const url = baseUrl + '/account/'+ accountId;
     axios.patch(url, {
      password: newpass
     }, headers);
    dispatcher({
      type: ACTIONS.UPDATE_SUCCESS,
      authData: storedAuth
     });
    }
  )
  .catch(err=>{
    storedAuth.error = 'Invalid current password';
    dispatcher({
      type: ACTIONS.UPDATE_FAILURE,
      authData: storedAuth
     });
  });
}

export const fetchPlayersAction = ( params) => {
    headers.headers['x-access-token'] = params.token;
    delete params.token;
    const typeId = ENUMS.accountTypeIds.player;
    var url = baseUrl + '/pgAccounts/';

    if( !params.forNetwork ) {
      params.typeId = typeId;
    }

    url += Helper.generateUrlQuery(params);
    return axios.get(url, headers);

    // if( params.forNetwork ) {
    //   url += '?query'    // Just for further string appendings..
    // } else {
    //   url += params.accountId + '/children?query&typeId=' + typeId + '&parentId=' + params.parentId;
    // }
    // if(params.limit){
    //   url = url +'&limit=' + params.limit;
    // }
    // if(params.page){
    //   url = url +'&page=' + params.page;
    // }
    // return axios.get(url, headers);
};

export const fetchStatementsAction = params => {
  headers.headers['x-access-token'] = params.token;
  delete params.token;
  let url = `${baseUrl}/transaction`;
  params.type = 'movement';
  if( params.forNetwork ) {
    params.id = params.accountId;
    delete params.accountId;
  }
  url += Helper.generateUrlQuery(params);
  return axios.get(url, headers);
};

export const fetchStatementsSummaryAction = params => {
  headers.headers['x-access-token'] = params.token;
  let url = `${baseUrl}/transaction/${params.accountId}/players/summary`;
  return axios.get(url, headers);
};

export const fetchBonusDataAction = params => {
  headers.headers['x-access-token'] = params.token;
  let url = `${baseUrl}/pgBonus`;
  url += Helper.generateUrlQuery(params);
  return axios.get(url, headers);
};

export const fetchBonusConfigsAction = params => {
  headers.headers['x-access-token'] = params.token;
  let url = `${baseUrl}/pgBonusConfigs` + Helper.generateUrlQuery(params);
  return axios.get(url, headers);
};

export const downloadInvoiceAction = params => {
  let url = `${baseUrl}/invoice`;
  url += Helper.generateUrlQuery(params);
  return axios({
    url: url,
    method: 'GET',
    headers: {'x-access-token': params.token},
    responseType: 'blob'
  });
};

export const fetchChildrenByRoleAction = params => {
  headers.headers['x-access-token'] = params.token;
  delete params.token;
  let url = `${baseUrl}/pgBonusByParentId`;

  url += Helper.generateUrlQuery(params);
  return axios.get(url, headers);
};

export const setSkinAction = dispatcher => {
  let skinDetails = null;
  for( let skin of skins ) {
    if( window.location.href.includes(skin.domain) ) {
      skinDetails = skin;
      break;
    }
  }
  // If the skin is not found, get the first one
  //
  if( !skinDetails ) {
    skinDetails = skins[0];
  }

  let params = queryParser.parse(window.location.search);
  if(params.hierarchyId && Number.isInteger(parseInt(params.hierarchyId))) {
    hierarchyId = parseInt(params.hierarchyId);
  } else {
    hierarchyId = skinDetails.hierarchyId;
  }

  // Set it in the store
  dispatcher({
    type: ACTIONS.SET_SKIN_DETAILS,
    skinDetails
  });
};
