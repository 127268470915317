import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { getAccountAction } from '../../../actions/Account';
import { EditAccountDetails } from './components';
import { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

// .box {
//   width: 180px;
//   height: 180px;
//   background: yellow;
//   margin: 20px 50px;
// }
// .circle {
//   border-radius: 50%;
// }
// .circleWithBorders {
//   border-radius: 50%;
//   border: 10px solid yellow;
//   box-shadow: inset 0px 0px 0px 5px red;
//   box-sizing: border-box; /* Include padding and border in element's width and height */
// }

const Account = props => {
  const classes = useStyles();
  const authData = useSelector(state => state.authData);
  const accountId = props.match.params.id  || authData.accountId;
  const [accountData, setAccountData] = useState({});

  const getAccountDetails = async () => {
    const response = await getAccountAction({accountId: accountId, token: authData.token});
    setAccountData(response.data.result.account);
  };

  useEffect(() => {
    getAccountDetails();
  }, []);
 
  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <EditAccountDetails account={accountData}/>
        </Grid>
      </Grid>
    </div>
  );
};

export default Account;
