import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import Helper from '../../../../../common/helper';
import t from '../../../../../configs/translations';

import {
  Grid,
  Paper,
  Typography
} from '@material-ui/core';
import CheckCircleRoundedIcon from '@material-ui/core/SvgIcon/SvgIcon';
import { useSelector } from 'react-redux';
import ENUMS from '../../../../../configs/enums';

const useStyles = makeStyles(theme => ({
  root: {
    margin: "1% 0",
    padding: "5%",
    borderRadius: 0,
    background: `linear-gradient(0deg, ${theme.palette.primary.main} 50%, ${theme.palette.primary.dark} 98%)`,
    '& h4': {
      color: theme.palette.white,
    },
    '&.white': {
      padding: "3% 5%",
      background: "none",
      '& h4': {
        fontSize: "1rem",
        color: theme.palette.primary.main
      }
    }
  }
}));


const BonusTotal = props => {
  const { className, data, type, currency, ...rest } = props;
  const roleId = useSelector(state => state.authData.subTypeId);
  let isShop = roleId === ENUMS.roleIds.shop;
  const isDistributor = roleId === ENUMS.roleIds.distributor;
  const isConsultant = roleId === ENUMS.roleIds.consultant;
  const selectedAccount = useSelector(state => state.selectedAccount);
  if ((isConsultant || isDistributor) && selectedAccount.subTypeId) {
    isShop = selectedAccount.subTypeId === ENUMS.roleIds.shop
  }
  const classes = useStyles();

  return (
    <Paper className={clsx({[classes.root]: true, 'white': (!isShop && type === 'bonus')})}>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={8}>
          <Typography variant="h4">{t[`${type}Total`]}</Typography>
        </Grid>
        <Grid item xs={4}>
          { !!data &&
            <Typography style={{textAlign: 'end'}} variant="h4">{ Helper.formatMoney({amount: data, currency: currency}) }</Typography>
          }
        </Grid>
      </Grid>
    </Paper>

  );
};

BonusTotal.propTypes = {
  className: PropTypes.string
};

export default BonusTotal;
