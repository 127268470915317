import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: 0,
    zIndex: '1000',
    alignItems: 'center'
  }
}));

const Loader = () => {
  const classes = useStyles();
  const isLoading = useSelector(state => state.loader.isLoading);

  return (
    <React.Fragment >
      { isLoading &&
        <div className={classes.root}>
          <CircularProgress disableShrink/>
        </div>
      }
    </React.Fragment >
  );
};

export default Loader;
