import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import t from '../../../../../configs/translations';
import ENUMS from '../../../../../configs/enums';
import { SellerBonus, ShopBonus } from './components';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import  LevelProgress  from '../../../Tabs/BonusTab/components/LevelProgress';
import { 
  Typography,
  ListItem,
  Link,
  Grid 
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  logoContainer : {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2)
  },
  logoImage: {
    height: 90
  },
  subTitle: {
    textAlign: "center",
    color: theme.palette.primary.main,
    textTransform: "uppercase"
  },
  quote: {
    textAlign: "center",
    color: theme.palette.primary.main,
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  bottomBorder: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginTop: 5
  },
  shiftContent: {
    marginBottom: theme.spacing(20),
  }
}));
const DailyBonuses = props => {
  const classes = useStyles();
  const dailyBonus = useSelector(state => state.dailyBonus.unlockedBonuses);
  const roleId = useSelector(state => state.authData.subTypeId);
  let isShop = roleId === ENUMS.roleIds.shop;
  const isDistributor = roleId === ENUMS.roleIds.distributor;
  const isConsultant = roleId === ENUMS.roleIds.consultant;
  const selectedAccount = useSelector(state => state.selectedAccount);
  if ((isConsultant || isDistributor) && selectedAccount.subTypeId) {
    isShop = selectedAccount.subTypeId === ENUMS.roleIds.shop
  }

  const [isShopDialogOpened, setIsShopDialogOpened] = useState(false);
  const logoImg = useSelector(state => state.skin.logo);
  return(
    <React.Fragment>
      {isShopDialogOpened ?
        <React.Fragment>
         <Typography className={classes.subTitle} variant="h4">{t.almostAchieviedGoals}</Typography>
         <Typography  variant="h6" className={classes.quote}>{t.hereAreYourGoals}</Typography>
         <Grid container className={classes.shiftContent}>
          {dailyBonus.pendingGoals.map(item=>(
            <React.Fragment key={item.account}>
              <Grid item xs={4} sm={8} className={classes.bottomBorder}>
                <div>{item.account}</div>
              </Grid>
              <LevelProgress data={item} type={item.type}/>
            </React.Fragment>
          ))}
        </Grid>
      </React.Fragment> :
      <React.Fragment>
       <Typography className={classes.logoContainer} variant="body2">
        <img
          alt="logo"
          className={classes.logoImage}
          src={`images/logos/${logoImg}`}
        />
       </Typography>
       <Typography className={classes.subTitle} variant="h4">{t.congrats}</Typography>
       <Typography  variant="h6" className={classes.quote}>{t.newGoalsAchieved}</Typography>
       {isShop ?
        <React.Fragment>
          <ShopBonus type="player" data={dailyBonus.byPlayers}/>
          <ShopBonus type="deposit" data={dailyBonus.byDeposits}/>
        </React.Fragment> :
        <React.Fragment>
          <SellerBonus type="player" data={dailyBonus.byPlayers}/>
          <SellerBonus type="deposit" data={dailyBonus.byDeposits}/>
          <Typography variant="body2">
            <Link onClick={()=>setIsShopDialogOpened(true)}>
              <ListItem className={classes.topBorder} style={{fontSize: 12}}>
                {t.seeYourGoals} <DoubleArrowIcon className={classes.doubleArrow} />
              </ListItem>
            </Link>
          </Typography>
        </React.Fragment>
       }
      </React.Fragment>
    }
  </React.Fragment>
  )
}
DailyBonuses.propTypes = {
  // dailyBonuses: PropTypes.object.isRequired
};
export default DailyBonuses;
