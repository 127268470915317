import mainTheme from './main';
import { colors } from '@material-ui/core';
const white = '#FFFFFF';
const black = '#000000';

export default {
  ...mainTheme,
  primary: {
    contrastText: white,
    dark: colors.indigo[900],
    main: colors.indigo[500],
    light: colors.indigo[100]
  },
};
