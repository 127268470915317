import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, CardHeader, Divider, Grid, IconButton, Link } from '@material-ui/core';
import { fetchBonusDataAction, getAccountAction } from '../../../actions/Account';
import { bonusTypes } from '../../../configs/enums';

import { AccountProfile, AccountDetails } from './components';
import { Bonus, Commission } from '../Dashboard/components';
// import clsx from 'clsx';
// import RefreshIcon from '@material-ui/core/SvgIcon/SvgIcon';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { Link as RouterLink } from 'react-router-dom';
import ENUMS from '../../../configs/enums';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  addIcon: {
    width: 40,
    height: 40
  }
}));

const AccountView = props => {
  const classes = useStyles();
  const authData = useSelector(state => state.authData);
  const accountId = parseInt(props.match.params.id) || authData.accountId;
  const [accountData, setAccountData] = useState({});
  const [bonusData, setBonusData] = useState({});
  const [showBonuses, setShowBonuses] = useState(false);

  const getAccountDetails = async () => {
    const response = await getAccountAction({accountId: accountId, token: authData.token});
    console.log('getAccount response: ', response);
    setAccountData(response.data.result.account);
    const account = response.data.result.account;
    const isShow = (account.subTypeId === ENUMS.roleIds.consultant
                    || account.subTypeId === ENUMS.roleIds.distributor) ? false : true
    setShowBonuses(isShow);
  };

  const fetchBonusData = async () => {
    try {
      const response = await fetchBonusDataAction({ token: authData.token });
      setBonusData(response.data.result.bonuses);
    } catch (err) {
      console.log(err);
      setBonusData({});
    }
  };

  useEffect(() => {
    console.log('Account useEFfect');
    getAccountDetails();
    fetchBonusData();
  }, []);

  // useEffect(() => {
  //   console.log('Account useEFfect-2');
  //   console.log('accountData: ', accountData);
  // }, [accountData]);

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item md={6} xs={12}>
          <AccountProfile account={accountData} />
        </Grid>
        <Grid item md={6} xs={12}>
          <AccountDetails account={accountData} />
        </Grid>


        {/*<Grid item xs={12}>*/}
        {/*  <Card>*/}
        {/*    <CardHeader*/}
        {/*      action={*/}
        {/*        <Link*/}
        {/*          component={RouterLink}*/}
        {/*          to={`/account/create`}*/}
        {/*        >*/}
        {/*        <IconButton size="small">*/}
        {/*          <AddIcon className={classes.addIcon}/>*/}
        {/*        </IconButton>*/}
        {/*        </Link>*/}
        {/*      }*/}
        {/*      title="New player"*/}
        {/*    />*/}
        {/*  </Card>*/}
        {/*</Grid>*/}

        {/* <Grid item xs={12}>
          <Card>
            <CardHeader
              action={
                <Link
                  component={RouterLink}
                  to={`/bonus/edit/${accountId}`}
                >
                <IconButton size="small">
                  <EditIcon />
                </IconButton>
                </Link>
              }
              title="Earnings"
            />
          </Card>
        </Grid> */}

        {showBonuses &&
          <div>
            <Grid item md={4} xs={12}>
              <Commission currency={bonusData.currency} data={bonusData ? bonusData[bonusTypes.commission] : {}} />
            </Grid>

            <Grid item md={4} xs={12}>
              <Bonus type={"player"} data={bonusData ? bonusData[bonusTypes.activePlayers] : {}} />
            </Grid>

            <Grid item md={4} xs={12}>
              <Bonus type={"deposit"} data={bonusData ? bonusData[bonusTypes.deposit] : {}} />
            </Grid>
          </div>}
      </Grid>
    </div>
  );
};

export default AccountView;
