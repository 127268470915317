import ACTIONS from '../actionsEnum'

export const authData = (state = {}, action) => {
    switch (action.type) {
        case ACTIONS.LOGIN_SUCCESS:
            return action.authData;
        case ACTIONS.LOGIN_FAILURE:
            return action.authData;
        case ACTIONS.USER_LOGOUT:
            return action.authData;
        case ACTIONS.UPDATE_FAILURE:
            return action.authData;
        case ACTIONS.UPDATE_SUCCESS:
            return action.authData;
        default:
            return state
    }
};

export const dailyBonus = (state = {}, action) => {
    switch (action.type) {
        case ACTIONS.DAILY_BONUS_RECEIVED:
            return {...state, isReceived: true, unlockedBonuses: action.unlockedBonuses};
        case ACTIONS.DAILY_BONUS_SHOWN:
            return {...state, isShown: true};
        default:
            return state
    }
};

export const selectedAccount = (state = {}, action) => {
    switch (action.type) {
        case ACTIONS.SET_ACCOUNT:
            return { id: action.accountId, subTypeId: action.subTypeId};
        case ACTIONS.UNSET_ACCOUNT:
            return {};
        default:
            return state
    }
};
