import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import ENUMS from '../../../configs/enums';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import { useSelector } from "react-redux";
import t from '../../../configs/translations'
import {
  Card,
  Grid,
  CardActions,
  CardContent,
  Avatar,
  Checkbox,
  Table,
  Link,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  IconButton,
  Tooltip
} from '@material-ui/core';

// import { getInitials } from 'helpers';
const avatarUrl = '/images/avatars/anonym.png';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    // minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  accountType: {
    textTransform: 'capitalize'
  }
}));

const PlayersTable = props => {
  const { className, onChangeOrder, order, players, rowsPerPage, Page,  ...rest } = props;
  const classes = useStyles();
  const roleId = useSelector(state => state.authData.subTypeId);
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Grid item sm={12} md={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    {roleId === ENUMS.roleIds.seller ||
                      roleId === ENUMS.roleIds.salesman &&
                      <React.Fragment>
                        <TableCell>Type</TableCell>
                        <TableCell>Parent</TableCell>
                      </React.Fragment>
                    }
                    {/*<TableCell>Balance</TableCell>*/}
                    <TableCell>Statements</TableCell>
                    <TableCell>
                      <Grid container>
                        {t.totalDeposit}
                          {(props.order == -1) ? 
                          <ArrowDropDownIcon onClick={() => props.onChangeOrder()} /> :
                          <ArrowDropUpIcon onClick={() => props.onChangeOrder()} />}
                      </Grid>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {players.map(user => (
                    <TableRow className={classes.tableRow} hover key={user._id}>
                      {/*<TableCell>{user._id}</TableCell>*/}
                      <TableCell>
                        <Link component={RouterLink} to={`/account/${user._id}`} variant="h6" >
                          <div className={classes.nameContainer}>
                            <Avatar className={classes.avatar} src={avatarUrl} >{user.username}</Avatar>
                            <Typography variant="body1">{user.username}</Typography>
                          </div>
                        </Link>
                      </TableCell>
                      {roleId === ENUMS.roleIds.seller ||
                       roleId === ENUMS.roleIds.salesman &&
                        <React.Fragment>
                          <TableCell className={classes.accountType}>{ENUMS.accountTypes[user.typeId]}</TableCell>
                          <TableCell>{user.skinId.username}</TableCell>
                        </React.Fragment>
                      }
                      {/*<TableCell>{user.wallets[0].balance} {user.wallets[0].currency}</TableCell>*/}
                      <TableCell>
                        <Tooltip title="View Statements">
                          <Link component={RouterLink} color="inherit" to={`/statements/${user._id}`}>
                          <IconButton className={classes.statementsLink} color="inherit" >
                            <MoneyIcon />
                          </IconButton>
                          </Link>
                        </Tooltip>
                      </TableCell>
                      <TableCell>{user.totalDeposit}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

PlayersTable.propTypes = {
  className: PropTypes.string,
  players: PropTypes.array.isRequired,
  onChangeOrder: PropTypes.func,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  order: PropTypes.number
};

export default PlayersTable;
