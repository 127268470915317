import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from 'react-router-dom'
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import Helper from '../../../common/helper';
import { Grid, Link, Card, Divider, Paper, Typography } from '@material-ui/core';
import { fetchStatementsSummaryAction, fetchPlayersAction, fetchBonusDataAction } from "../../../actions/Account";
import t from '../../../configs/translations';
import ACTIONS from '../../../store/actionsEnum';
import { bonusTypes } from '../../../configs/enums';

import {
  Budget,
  Bonus,
  BonusChart,
  CommissionChart,
  BonusTotal,
  DownloadInvoice,
  Earnings,
  Commission,
  TotalUsers,
  UsersByDevice
} from './components';
import ENUMS from '../../../configs/enums';

const IMMEDIATE_EARNING = 'immediateEarning';
const DEPOSIT = 'deposit';
const ACTIVE_PLAYERS = 'activePlayers';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    '& .MuiDivider-root': {
      margin: "5%",
      backgroundColor: "#ccc"
    }
  },
  bonusesTitle: {
    margin: "2%",
    color: theme.palette.primary.main,
    fontWeight: 700
  }
}));

const getTypeName = (type) => (type == 'activePlayers') ? 'player' : (type == 'immediateEarning') ? 'contract' : type;

const Dashboard = () => {
  const classes = useStyles();
  const [statementsSummary, setStatementsSummary] = useState({});
  const [totalPlayers, setTotalPlayers] = useState(0);
  const [fullTotal, setFullTotal] = useState(0);
  const [bonusData, setBonusData] = useState({});
  const [bonusConfigs, setBonusConfigs] = useState({});
  const authData = useSelector(state => state.authData);
  let isShop = authData.subTypeId === ENUMS.roleIds.shop;
  const isDistributor = authData.subTypeId === ENUMS.roleIds.distributor;
  const isConsultant = authData.subTypeId === ENUMS.roleIds.consultant;
  const selectedAccount = useSelector(state => state.selectedAccount);
  let accountId = authData.accountId;
  const dailyBonusStore = useSelector(state => state.dailyBonus);
  const selectedMonth = useSelector(state => state.month);
  const [isDailyBonusOpened, setIsDailyBonusOpened] = useState(true);
  const dispatcher = useDispatch();

  if ((isConsultant || isDistributor) && selectedAccount.subTypeId) {
    isShop = selectedAccount.subTypeId === ENUMS.roleIds.shop
    accountId = selectedAccount.id;
  }

  const handleDialogClose = () => {
    setIsDailyBonusOpened(false);
    dispatcher({ type: ACTIONS.DAILY_BONUS_SHOWN });
  };
  const fetchTotalStatements = async () => {
    const response = await fetchStatementsSummaryAction({
      accountId: authData.accountId,
      token: authData.token,
    },
    );
    setStatementsSummary(Object.values(response.data.result.summary)[0] || {});
  };

  const fetchTotalPlayers = async () => {
    const response = await fetchPlayersAction({
      token: authData.token,
      forNetwork: true
    },
    );
    setTotalPlayers(response.data.result.count);
  };

  const fetchBonusData = async () => {
    try {
      dispatcher({ type: ACTIONS.DATA_FETCHING_START });
      let params = Helper.putDateParams(selectedMonth);
      params.accountId = accountId;
      const response = await fetchBonusDataAction({
        ...params,
        token: authData.token
      },
      );
      dispatcher({ type: ACTIONS.DATA_FETCHING_END });
      setBonusData(response.data.result.bonuses);
      let configs = {};
      response.data.result.configs.forEach(c => configs[c.type.replace('pg-', '')] = c);
      setBonusConfigs(configs);
    } catch (err) {
      console.log(err);
      setBonusData({});
      setBonusConfigs({});
    }
  };

  useEffect(() => {
    if (!!selectedMonth && selectedMonth._isAMomentObject) {
      setBonusData({});
      fetchBonusData();
    }
  }, [selectedMonth]);

  useEffect(() => {
    if (bonusData && bonusData[bonusTypes.commission] && bonusData.total) {
      const commissionFee = bonusData[bonusTypes.commission].fee || 0;
      setFullTotal(bonusData.total + commissionFee);
    } else {
      setFullTotal(0);
    }
  }, [bonusData]);

  return ((isDistributor || isConsultant) && !selectedAccount.id) ? <Redirect to='/network' /> :
    (<div className={classes.root}>

      {/*{ dailyBonusStore.isReceived && !dailyBonusStore.isShown &&  */}
      {/*<ModalDialog open={isDailyBonusOpened} onClose={handleDialogClose} type={'bonus'} /> }*/}

      <Grid container spacing={1}>
        {!isShop &&
          <Grid item md={4} xs={12}>
            <CommissionChart config={bonusConfigs.commission} data={bonusData[bonusTypes.commission]} currency={bonusData.currency} />
          </Grid>
        }

        {isShop &&
          <Grid item md={4} xs={12}>
            <Card>
              <BonusChart bonusType={"player"} currency={bonusData.currency} bonusData={bonusData[bonusTypes.activePlayers] || {}} />
              <Divider />
              <BonusChart bonusType={"deposit"} currency={bonusData.currency} bonusData={bonusData[bonusTypes.deposit] || {}} />
            </Card>
          </Grid>
        }

        <Grid item md={4} xs={12}>
          <Typography type="h4" className={classes.bonusesTitle}>{t.achievedGoals}</Typography>
          {[IMMEDIATE_EARNING, ACTIVE_PLAYERS, DEPOSIT].map(type => {
            if (!bonusConfigs[type]) return '';
            return (<Bonus
              key={type}
              type={getTypeName(type)}
              config={bonusConfigs[type]}
              data={bonusData[bonusTypes[type]]}
              currency={bonusData.currency}
            />);
          })}
          <BonusTotal type={`bonus`} data={bonusData.total} currency={bonusData.currency} />
          {!isShop && <BonusTotal type={`full`} data={fullTotal} currency={bonusData.currency} />}
          <DownloadInvoice total={{ amount: bonusData.total, currency: bonusData.currency }} />
        </Grid>
      </Grid>
    </div>
    )
};

export default Dashboard;
