const ACTIONS = {
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  USER_LOGOUT: "USER_LOGOUT",
  UPDATE_SUCCESS: "UPDATE_SUCCESS",
  UPDATE_FAILURE: "UPDATE_FAILURE",
  START_LOGIN: "START_LOGIN",
  DATA_FETCHING_START: "DATA_FETCHING_START",
  DATA_FETCHING_END: "DATA_FETCHING_END",
  DAILY_BONUS_RECEIVED: "DAILY_BONUS_RECEIVED",
  DAILY_BONUS_SHOWN: "DAILY_BONUS_SHOWN",
  CHANGE_SELECTED_MONTH: "CHANGE_SELECTED_MONTH",
  SET_SKIN_DETAILS: "SET_SKIN_DETAILS",
  SET_ACCOUNT: "SET_ACCOUNT",
  UNSET_ACCOUNT: "UNSET_ACCOUNT"
};

export default ACTIONS
