import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import t from '../../../../configs/translations';
import {DownloadInvoice} from '../../../../components/views/Dashboard/components';
import { roleNames, bonusTypes } from '../../../../configs/enums';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
    info: {
        color: theme.palette.primary.main
    },
    shopInfo: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        color: theme.palette.primary.main,
    },
    borderTop: {
        borderTop: `1px solid ${theme.palette.divider}`
    },
    downloadInvoice: {
        float: 'right'
    }
}));
const AccountInfo = props =>{
    const classes = useStyles();
    const { account, total } = props;
    const selectedMonth = useSelector(state => state.month);
    const currentDate = moment.utc(selectedMonth, "YYYY-MM-DD");
    const birthDate = moment.utc(account.personalInfo.birthDate, "YYYY-MM-DD");
    const monthsActivity = currentDate.diff(birthDate, "months");
    let activePlayersCount = 0;
    if(account && account.bonuses && account.bonuses[bonusTypes.activePlayers]) {
        activePlayersCount = account.bonuses[bonusTypes.activePlayers].all;
    }

    return(
    <React.Fragment>
        <Grid container>
        <Grid item xs={12} sm={12}>
            <Typography variant="h5" className={classes.shopInfo}>
                {t.shopInfo}
            </Typography>
        </Grid>
            <Grid item xs={12} sm={6}>
                <Typography variant="h6">
                    {t.username}: <span className={classes.info}>{account.username}</span>
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Typography variant='h6'>
                    {t.role}: <span className={classes.info}>{roleNames[account.subTypeId]}</span>
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Typography variant='h6'>
                    {t.country}: <span className={classes.info}>{account.personalInfo.countryCode}</span>
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Typography variant='h6'>
                    {t.status}: <span className={classes.info}>{account.status}</span>
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Typography variant='h6'>
                    {t.activation}: <span className={classes.info}>{moment.utc(account.personalInfo.birthDate).format('MM-DD-YYYY')}</span>
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Typography variant='h6'>
                    {t.registeredUsers}: <span className={classes.info}>{account.childrenCount}</span>
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Typography variant='h6'>
                    {t.email}: <span className={classes.info}>{account.personalInfo && account.personalInfo.email}</span>
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Typography variant='h6'>
                    {t.monthsActivity}: <span className={classes.info}>{monthsActivity > 0 ? monthsActivity : 0}</span>
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Typography variant='h6'>
                    {t.activePlayers}: <span className={classes.info}>{activePlayersCount}</span>
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} className={classes.borderTop}>
                <DownloadInvoice invoicer={account._id} total={total} />
            </Grid>
        </Grid>
    </React.Fragment>
    );
}
export default AccountInfo;
