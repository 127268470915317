import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Doughnut } from 'react-chartjs-2';
import t from '../../../../../configs/translations';
import Helper from '../../../../../common/helper';

import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    padding: "1vh 0"
  },
  header: {
    fontSize: "1.1em",
    textAlign: "center",
    marginTop: "5%",
    '&.player': {
      color: theme.palette.warning.main
    },
    '&.deposit': {
      color: theme.palette.info.main
    }
  },
  playerColor: {
    color: theme.palette.warning.main
  },
  depositColor: {
    color: theme.palette.info.main
  },
  progress: {
    marginTop: theme.spacing(2)
  },
  step: {
    color: theme.palette.text.main
  },
  title: {
    fontWeight: 700
  },
  chartContainer: {
    position: 'relative',
    height: 160,
    padding: "3%",
    backgroundImage: 'url(/images/commissionChartBg.png)',
    backgroundRepeat: "no-repeat",
    backgroundSize: "52%",
    backgroundPosition: "59% -7%"
  },
  chartInnerText: {
    width: "100%",
    height: "100%",
    position: 'absolute',
    top: 0,
    left: "1%",
    padding: "5% 0",
    textAlign: "center"
  },
  stats: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  earning: {
    textAlign: 'center',
    padding: theme.spacing(1)
  },
}));


const BonusChart = props => {
  const { bonusData, bonusType, currency } = props;
  const classes = useStyles();
  const theme = useTheme();
  const colorsByType = {
    player: theme.palette.warning.main,
    deposit: theme.palette.info.main
  };

  let currentObjective = {
    level: 0,
    threshold: 0,
    achievement: 0,
    bonus: {}
  }
  if (!!bonusData && !!bonusData.bonuses) {
    currentObjective = bonusData.bonuses.find(obj => obj.achievement < obj.threshold) || currentObjective;
    if (!currentObjective) {
      let length = bonusData.bonuses.length;
      currentObjective = {};
      if (length > 0) {
        currentObjective = bonusData.bonuses[length - 1];
      }
    }
  } else {
    return false;
  }

  const data = {
    datasets: [
      {
        data: [
          currentObjective.achievement || 0,
          currentObjective.threshold ? (currentObjective.threshold - currentObjective.achievement) : 0
        ],
        backgroundColor: [
          bonusType ? colorsByType[bonusType] : theme.palette.primary.main,
          "#f0f1f1"
        ],
        borderWidth: 1,
        borderColor: "#f0f1f1",
        hoverBorderColor: "#f0f1f1"
      }
    ],
    labels: ['Achieved', 'Remained']
  };

  const options = {
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: true,
    cutoutPercentage: 94,
    layout: { padding: 0 },
    showTooltips: true,
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary
    }
  };

  return (
    <React.Fragment>
      <Typography variant="h3" className={clsx(classes.header, `${bonusType}`)}>{t[`${bonusType}Bonus`]}</Typography>
      <div className={classes.chartContainer}>
        <Doughnut
          data={data}
          options={options}
        />
        <div className={classes.chartInnerText}>
          {!!currentObjective.level && <Typography variant="h6" className={classes.step}> {`Step ${currentObjective.level}`} </Typography>}
          {currentObjective.bonus &&
            <div>
              <Typography variant="h3" className={classes[`${bonusType}Color`]}> {Helper.formatMoney({ amount: currentObjective.bonus.amount, currency: currency })} </Typography>
              <Typography variant="h6" className={classes.step}> {t.nextGoal} </Typography>
              <Typography variant="h6" className={classes[`${bonusType}Color`]}>{bonusType === 'player' ?
                <span>{currentObjective.achievement} / {currentObjective.threshold}</span> :
                <span>{Helper.formatMoney({ amount: currentObjective.achievement, currency: currency })}/{Helper.formatMoney({ amount: currentObjective.threshold, currency: currency })}</span>}
              </Typography>
              <Typography variant="h6" className={classes[`${bonusType}Color`]}>{bonusType === 'player' ? t.activePlayers : t.deposit} </Typography>
            </div>
          }
        </div>
      </div>
    </React.Fragment>

  );
};

BonusChart.propTypes = {
  className: PropTypes.string
};

export default BonusChart;
