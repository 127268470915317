import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import { DialogContent, List, Grid, Link } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { Typography } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';

import {InfoDialog, BonusDialog} from './dialogs'

const useStyles = makeStyles(theme => ({
  dialog: {
    '& .MuiPaper-rounded': {
      borderRadius: 33,
      minHeight: 200,
      width: "100%",
      margin: '5%'
    },
  },
  dialogContent: {
    fontSize: 'small',
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    '& .MuiListItem-root': {
      paddingTop: 4,
      paddingBottom: 4
    }
  },
  logoContainer : {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2)
  },
  logoImage: {
    height: 105
  },
  subTitle: {
    textAlign: "center",
    color: theme.palette.primary.main,
    textTransform: "uppercase"
  },
  quote: {
    textAlign: "center",
    color: theme.palette.primary.main,
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  bottomBorder: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginTop: "5%"
  },
  topBorder: {
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  infoIcon: {
    fontSize: 20,
    marginLeft: 3,
    color: theme.palette.text.main
  },
  bonusContent: {
    color: theme.palette.primary.main,
    marginBottom: 20
  },
  infoDialogTitle: {
    fontSize: 21,
    color: theme.palette.primary.main,
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    display: 'flex',
  },
  doubleArrow: {
    color: theme.palette.primary.main,
  },
  close:{
    position: 'absolute',
    marginTop: 15,
    marginRight: 15,
    right: 0,
    top: 0,
    color: theme.palette.primary.main,
    transform: 'scale(1.6)'
  },
}));

const DIALOGS = {
  info: InfoDialog,
  bonus: BonusDialog
};

const ModalDialog = props => {
  const { onClose, open, type, subType, data } = props;
  const classes = useStyles();

  // Using the DialogView as a state property brings to errors,
  // needs for more investigations
  //
  // const [DialogView, setDialogView] = useState(null);

  let DialogView = null;
  if( !!type ) {
    DialogView = DIALOGS[type];
  }

  return(
      <Dialog onClose={onClose} className={classes.dialog} open={open}>
        <DialogTitle>
          <Typography><CancelIcon className={classes.close} onClick={onClose}/></Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          { !!DialogView && <DialogView type={subType} data={data} /> }
        </DialogContent>
      </Dialog>
  );
};

export default ModalDialog;
