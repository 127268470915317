import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import t from '../../../../../../configs/translations'
import { createBrowserHistory } from 'history';

import {
  Paper,
  Tab,
  Tabs,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    backgroundColor: theme.palette.background.default,
  },
  tab: {
    padding: '6px 4px',
    textTransform: 'capitalize',
    fontSize: 14,
    minHeight: 40,
    background: `linear-gradient(135deg, ${theme.palette.text.main} 59%, ${theme.palette.divider} 95%)`,
  },
   notification: {
    backgroundColor: theme.palette.text.dark,
    display: 'inline-block',
    minWidth: 25,
    marginLeft: 4,
    borderRadius: '35%',
    fontSize: 11,
    borderTop: `1px solid ${theme.palette.text.primary}`,
    borderBottom: `1px solid ${theme.palette.white}`
  },
  tabs: {
    minHeight: 40,
    color: theme.palette.white,
    '& .Mui-selected': {
      background: `linear-gradient(135deg, ${theme.palette.primary.main} 60%, ${theme.palette.primary.light} 98%)`,
      '& .MuiTab-wrapper': {
        '& span': {
          backgroundColor: theme.palette.warning.main,
          borderTop: `1px solid ${theme.palette.warning.dark}`
        },
      }
    }
  },
}));

const SectionTabs = props => {
  const { className, onSidebarOpen, ...rest } = props;
  const classes = useStyles();
  const browserHistory = createBrowserHistory();
  const [selectedTab, setSelectedTab] = React.useState(browserHistory.location.pathname);
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  return (
      <Paper className={classes.root}>
        <Tabs
          className={classes.tabs}
          variant="fullWidth"
          value={selectedTab}
          onChange={handleTabChange}
          indicatorColor="primary"
        >
          <Tab
            className={classes.tab}
            // label={<p>{t.dashboard}<span className={classes.notification}>1</span></p>}
            label={<p>{t.dashboard}</p>}
            component={RouterLink}
            to={`/dashboard`}
            value={`/dashboard`}
          />
          <Tab
            className={classes.tab}
            // label={<p>{t.commission}<span className={classes.notification}>2</span></p>}
            label={<p>{t.commission}</p>}
            component={RouterLink}
            to={`/commission`}
            value={`/commission`}
          />
          <Tab
            className={classes.tab}
            // label={<p>{t.bonus}<span className={classes.notification}>4</span></p>}
            label={<p>{t.bonusNav}</p>}
            component={RouterLink}
            to={`/bonus`}
            value={`/bonus`}
          />
        </Tabs>
      </Paper>
  );
};

// SectionTabs.propTypes = {
//   className: PropTypes.string,
//   onSidebarOpen: PropTypes.func
// };

export default SectionTabs;
