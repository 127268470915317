import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { updatePassword } from '../../../../../actions/Account';
import { useSelector, useDispatch } from "react-redux";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import validate from 'validate.js';
import { Redirect } from 'react-router-dom';
import t from '../../../../../configs/translations'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  TextField
} from '@material-ui/core';
const schema = {
  current: {
    presence: { allowEmpty: false, message: 'password is required' },
    length: {
      minimum: 6,
      maximum: 16,
      message: 'password must be between 6-16'
    }
  },
  new: {
    presence: { allowEmpty: false, message: 'password is required' },
    length: {
      minimum: 6,
      maximum: 16,
      message: 'password must be between 6-16'
    }
  },
  confirm: {
    presence: { allowEmpty: false, message: 'password is required' }, 
    length: {
      minimum: 6,
      maximum: 16,
      message: 'password must be between 6-16'
    }
  }
};
const useStyles = makeStyles(theme  => ({
  root: {},
  success: {
    backgroundColor: theme.palette.primary.main,
    margin: 5
  },
  error: {
    backgroundColor: theme.palette.error.dark,
    margin: 5
  },
  errorMessage: {
    color: theme.palette.error.main,
    paddingLeft: 15,
    fontSize: 12
  },
  icon: {
    fontSize: 15,
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    fontSize: 15,
    display: 'flex'
  },
}));

const Password = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const authData = useSelector( state => state.authData);
  const dispatcher = useDispatch();
  const [updated, setUpdated] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [passwordCheck, setPasswordCheck] = useState(true);
  var error = useSelector(state => state.authData.error);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      current: '',
      new: '',
      confirm: '',
    },
    touched: {},
    errors: {}
  });

  const handleSubmit = event => {
    event.preventDefault();
    setTimeout(() => {
      setRedirect(true);
    }, 2000);
    if(formState.values.new!==formState.values.confirm) {
      setPasswordCheck(false);
    } else {
      const update = async () => {
        setPasswordCheck(true);
        const response = await updatePassword({username: authData.username, password: formState.values.current, loginFromBC: 1 ,
          newpass: formState.values.new, accountId: authData.accountId, token: authData.token,
          dispatcher: dispatcher});
          setUpdated(true);
      }
      update();
      setRedirect(false);
    }
  };

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);
  
  const handleChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };
  const hasError = field =>
  formState.touched[field] && formState.errors[field] ? true : false;
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
        onSubmit={handleSubmit}
      >
        <CardHeader
          subheader={t.updatePassword}
          title={t.password}
        />
        <Divider />
        <CardContent>
        <TextField
            fullWidth
            label={t.currentPassword}
            name="current"
            onChange={handleChange}
            error={hasError('username')}
            type="password"
            value={formState.values.current}
            error={hasError('current')}
            helperText={
              hasError('current') ? formState.errors.current[0] : null
            }
            variant="outlined"
          />
          <TextField
            fullWidth
            label={t.newPassword}
            name="new"
            onChange={handleChange}
            style={{ marginTop: '1rem' }}
            type="password"
            value={formState.values.new}
            error={hasError('new')}
            helperText={
              hasError('new') ? formState.errors.new[0] : null
            }
            variant="outlined"
          />
          <TextField
            fullWidth
            label={t.confirmPassword}
            name="confirm"
            onChange={handleChange}
            style={{ marginTop: '1rem' }}
            type="password"
            value={formState.values.confirm}
            error={hasError('confirm')}
            helperText={
              hasError('confirm') ? formState.errors[0] : null
            }
            variant="outlined"
          />
        </CardContent>
        <Divider />
        {!passwordCheck &&
          <span className={classes.errorMessage}>Passwords don't match</span>
        }
        {error && 
          <SnackbarContent
            className={classes.error}
            message= {<span><ErrorIcon className={classes.icon}/>{error}</span>}
          >
          </SnackbarContent>
       }
          {!error && updated && 
            <div>
              <SnackbarContent
                className={classes.success}
                message= {<span><CheckCircleIcon className={classes.icon}/>
                Updated succesfully!</span>}
                autohideduration={500}
              >
                <CheckCircleIcon/>
              </SnackbarContent>
              { redirect &&
                <Redirect to = "/dashboard"></Redirect>
              }
            </div>
          }
        <CardActions>
          <Button
            color="primary"
            variant="outlined"
            type="submit"
            disabled={!formState.isValid}
          >
            {t.update}
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

Password.propTypes = {
  className: PropTypes.string
};

export default Password;
