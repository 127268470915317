import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Grid } from '@material-ui/core';
import t from '../../../../../../configs/translations';
import Helper from '../../../../../../common/helper';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import ENUMS from '../../../../../../configs/enums';

const useStyles = makeStyles(theme => ({
  playerAccountIcon: {
    position: 'absolute',
    width: 25,
    height: 25,
    margin: 1
  },
  depositAccountIcon: {
    display: 'none'
  },
  depositCount: {
    position: 'absolute',
    backgroundImage: 'url(images/depositeBg.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    minWidth: 90,
    height: 25,
    padding: 4,
    left: -22,
    marginTop: -2,
    textAlign: 'right',
    color: theme.palette.white,
  },
  playerCount: {
    fontSize: '1em',
    position: 'absolute',
    color: theme.palette.white,
    backgroundColor: theme.palette.primary.main,
    borderRadius: "50%",
    padding: 4,
    textAlign: 'center',
    minWidth: 25,
    margin: theme.spacing(0, 3)
  },
  line: {
    backgroundColor: theme.palette.divider,
    borderRadius: 10,
    marginTop: 15
  },
  bonusType: {
    color: theme.palette.black,
    textTransform: "uppercase",
    textAlign: "center",
    marginTop: 5,
    fontWeight: 500,
    fontSize: '0.8em'
  },
  bonusCount: {
    color: theme.palette.black,
    marginTop: 5,
    fontWeight: 500,
    fontSize: '0.8em'
  },
  addCircleIcon: {
    color: theme.palette.primary.main,
    right: -5,
    marginTop: theme.spacing(-3),
    position: 'absolute'
  }
}));

const Level = props => {
  const classes = useStyles();
  const { type, goal, bonus, currency } = props;
  const roleId = useSelector(state => state.authData.subTypeId);
  const selectedAccount = useSelector(state => state.authData.subTypeId);
  let isShop = roleId === ENUMS.roleIds.shop
  const isDistributor = roleId === ENUMS.roleIds.distributor;
  const isConsultant = roleId === ENUMS.roleIds.consultant;
  if ((isConsultant || isDistributor) && selectedAccount.subTypeId) {
    isShop = selectedAccount.subTypeId === ENUMS.roleIds.shop
  }
  return (
    <Grid container className={classes.line}>
      <Grid item xs={3}>
        <img
          className={classes[`${type}AccountIcon`]}
          src="images/accountIcon.png"
        />
        <Typography variant="body2" className={classes[`${type}Count`]}>
          {type === 'deposit' ? Helper.formatMoney({ amount: goal, currency: currency }) : goal}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" className={classes.bonusType}>
          {t.bonusTypes[`${type}`]}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body2" className={classes.bonusCount}>
          {Helper.formatMoney({ amount: bonus, currency: currency })}
        </Typography>
        {!isShop && <AddCircleIcon className={classes.addCircleIcon} />}
      </Grid>
    </Grid>
  )
};

export default Level;
