import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { ListItem, Grid } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import t from '../../../../../../configs/translations';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Helper from '../../../../../../common/helper';

const useStyles = makeStyles(theme => ({
  playerIcon: {
    fontSize: '1.2em',
    color: theme.palette.warning.main
  },
  depositIcon: {
    fontSize: '1.2em',
    color: theme.palette.info.main
  },
  bottomBorder: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  name: {
    paddingTop: 3,
    paddingBottom: 3,
    justifyContent: 'flex-start',
    paddingLeft: 2
  },
  bonus: {
    color: theme.palette.primary.main
  },
  check: {
    height: 17,
    width: 17,
    marginLeft: 2,
    color: theme.palette.success.light
  },
  shiftContent: {
    marginBottom: theme.spacing(7)
  }
}));
const DailyBonuses = props => {
  const classes = useStyles();
  const { data, type } = props;
    return(
        <React.Fragment>
          <Grid container
           className={clsx({
            [classes.shiftContent]: type === 'deposit'
          })}>
            <Grid className={classes.bottomBorder} item xs={8}>
              <ListItem className={classes.name}>
                  <FiberManualRecordIcon className={classes[`${type}Icon`]}/>
                  <span>{t[`${type}Bonus`]}</span> 
              </ListItem>
              </Grid>
              <Grid className={classes.bottomBorder} item xs={4}>
                <ListItem className={classes.bonus}>{t.bonus}</ListItem>
              </Grid>
              {data.map( item =>
                (<React.Fragment key={item.player}>
                  <Grid className={classes.bottomBorder} item xs={4}>
                    <ListItem >{item.player}</ListItem>
                  </Grid>
                  <Grid className={classes.bottomBorder} item xs={4}>
                    <ListItem>{t.step}:{item.level}<CheckCircleRoundedIcon className={classes.check}/></ListItem>
                  </Grid>
                  <Grid className={classes.bottomBorder} item xs={4}>
                    <ListItem className={classes.bonus}>{ Helper.formatMoney(item.bonus)}</ListItem>
                  </Grid>
                </React.Fragment>)
              )}
            </Grid>
        </React.Fragment>
    )
}
DailyBonuses.propTypes = {
    data: PropTypes.array.isRequired
};
export default DailyBonuses;
