import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/styles';

import CommissionList from './List';
import Total from './Total';
import { fetchChildrenByRoleAction } from "../../../../actions/Account";
import ENUMS from '../../../../configs/enums';
import t from '../../../../configs/translations';
import { Typography, Paper } from '@material-ui/core';
import { TablePagination } from '@material-ui/core';
import ACTIONS from '../../../../store/actionsEnum';
import { useDispatch } from 'react-redux';
import Helper from '../../../../common/helper';
const useStyles = makeStyles(theme => ({
  root: {
    padding: 0
  },
  content: {
    padding: "0.5%"
  },
  tableTitle: {
    textAlign: "center",
    textTransform: "capitalize",
    marginBottom: "2%",
    color: theme.palette.primary.main,
    fontSize: "1.3em",
    lineHeight: "1.5em"
  },
  noRecords: {
    color: theme.palette.primary.main,
    textAlign: 'center'
  }
}));

const CommissionTab = props => {
  const classes = useStyles();
  const authData = useSelector(state => state.authData);
  const selectedMonth = useSelector(state => state.month);
  const selectedAccount = useSelector(state => state.selectedAccount);
  const accountId = selectedAccount.id || props.match.params.accountId  || authData.accountId;
  const [accounts, setAccounts] = useState([]);
  const dispatcher = useDispatch();
  const [totalData, setTotalData] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(2000);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(rowsPerPage);

  const fetchChildrenByRole = async () => {
    try {
      dispatcher({ type: ACTIONS.DATA_FETCHING_START });
      const params = Helper.putDateParams(selectedMonth);
      const response = await fetchChildrenByRoleAction({
        ...params,
        accountId: accountId,
        subTypes: `[${ENUMS.roleIds.shop}]`,
        token: authData.token,
        limit: rowsPerPage,
        sortBy: ENUMS.bonusTypes.commission,
        type: ENUMS.bonusTypes.commission,
        page: page + 1
      },
      );
      setCount(response.data.result.count);
      setAccounts(response.data.result.accounts);
      dispatcher({ type: ACTIONS.DATA_FETCHING_END });
      // Calculate and set total data
      //
      const arrAccounts = response.data.result.accounts;
      if (arrAccounts.length) {
        let totalFee = 0;
        let totalOverall = 0;
        arrAccounts.forEach(acc => {
            let c = acc.bonuses[ENUMS.bonusTypes.commission];
            if(c && c.fee) {
              totalFee += c.fee;
            }
            if(c && c.overall) {
              totalOverall += c.overall;
            }
        });
        setTotalData({
          overall: totalOverall,
          fee: totalFee,
          currency: arrAccounts[0].bonuses.currency
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if( !!selectedMonth && selectedMonth._isAMomentObject) {
      setPage(0);
      fetchChildrenByRole();
    }
  }, [selectedMonth]);

  useEffect(() => {
    if( !!selectedMonth && selectedMonth._isAMomentObject) {
      fetchChildrenByRole();
    }
  }, [rowsPerPage, page]);

  return (
    <div className={classes.root}>
      {accounts.length ?
        <React.Fragment>
          <Paper className={classes.content}>
            <Typography variant="h3" className={classes.tableTitle}>{t.monthlyProfit}</Typography>
          </Paper>
          <CommissionList accounts={accounts} />
          {/* <TablePagination
            component="div"
            count={count}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          /> */}
          { !!totalData.fee && <Total data={totalData} />}
        </React.Fragment>
        :<Typography variant="h3" className={classes.noRecords}>{t.noRecords}</Typography>
      }
    </div>
  );
};

export default CommissionTab;
