import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Typography,
  Divider,
  IconButton,
  Button,
  LinearProgress, Link, Hidden
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import ENUMS from '../../../../configs/enums';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  details: {
    display: 'flex'
  },
  avatar: {
    marginLeft: 'auto',
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0
  },
  progress: {
    marginTop: theme.spacing(2)
  },
  uploadButton: {
    marginRight: theme.spacing(2)
  },
  cardTextItem: {
    margin: "3%"
  },
  statementsLink: {
    fontSize: "1em",
    padding: "0",
    marginTop: "5%"
  }
}));

const Profile = props => {
  const { className, account, ...rest } = props;

  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <div className={classes.details}>
          <div>
            <Typography
              gutterBottom
              variant="h2"
            >
              {account.firstname} {account.lastname}
            </Typography>
            <Divider />
            <Typography
              className={classes.cardTextItem}
              color="textSecondary"
              variant="body1"
            >
              {`Balance: ${account.wallets ? account.wallets[0].balance + " " + account.wallets[0].currency : "" }`}
            </Typography>
            <Divider />
            <Typography
              className={classes.cardTextItem}
              color="textSecondary"
              variant="body1"
            >
              <span className={classes.label}>Role: </span>
              {ENUMS.roleNames[account.subTypeId]}
            </Typography>
            <Divider />

            <Link
              component={RouterLink}
              color="inherit"
              to={`/statements/${account._id}`}
            >
              <IconButton
                className={classes.statementsLink}
                color="inherit"
              >
                View Statements <MoneyIcon />
              </IconButton>
            </Link>


          </div>
          <Avatar
            className={classes.avatar}
            src='/images/avatars/anonym.png'
          />
        </div>
      </CardContent>
      <Divider />
    </Card>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
