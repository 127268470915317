import ACTIONS from '../actionsEnum'

export const loader = (state = {}, action) => {
  switch (action.type) {
    case ACTIONS.LOGIN_SUCCESS:
    case ACTIONS.LOGIN_FAILURE:
    case ACTIONS.DATA_FETCHING_END:
      return {isLoading: false};
    case ACTIONS.DATA_FETCHING_START:
    case ACTIONS.START_LOGIN:
      return {isLoading: true};
    default:
      return state
  }
};
