import mainTheme from './main';
import { colors } from '@material-ui/core';

export default {
  ...mainTheme,
  primary: {
    dark: colors.grey[900],
    main: colors.grey[800],
    light: colors.grey[500]
  }
}