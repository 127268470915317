import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter, Redirect } from 'react-router-dom';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { createAccount } from "../../../actions/Account";
import { useDispatch, useSelector } from "react-redux";
import ENUMS from '../../../configs/enums';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Link,
  FormHelperText,
  Checkbox,
  Card,
  Typography
} from '@material-ui/core';
// import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const schema = {
    firstName: {
      presence: { allowEmpty: false, message: 'is required' },
      length: {
        maximum: 32
      }
    },
    userName: {
      presence: { allowEmpty: false, message: 'is required' },
      length: {
        maximum: 32
      }
    },
    lastName: {
      presence: { allowEmpty: false, message: 'is required' },
      length: {
        maximum: 32
      }
    },
    email: {
      presence: { allowEmpty: false, message: 'is required' },
      email: true,
      length: {
        maximum: 64
      }
    },
    password: {
      presence: { allowEmpty: false, message: 'is required' },
      length: {
        maximum: 128
      }
    },
    repeatPassword: {
      presence: { allowEmpty: false, message: 'is required' },
      length: {
        maximum: 128
      }
    },
    profit: {
      presence: { allowEmpty: false, message: 'is required' },
      length: {
        maximum: 3
      }
    },
    country: {
      presence: { allowEmpty: false, message: 'is required' },
      length: {
        maximum: 128
      }
    },
    policy: {
      presence: { allowEmpty: false, message: 'is required' },
      checked: true
    },
    // currency: {
    //   presence: { allowEmpty: false, message: 'is required' },
    //   checked: true
    // }
  };
 const countries = [
  {'ccode' : 'AFG', 'cname' : 'Afghanistan'},
  {'ccode' : 'ARM', 'cname' : 'Armenia'},
  {'ccode' : 'BRA', 'cname' : 'Brazil'},
  {'ccode' : 'CAN', 'cname' : 'Canada'},
  {'ccode' : 'CHN', 'cname' : 'China'},
  {'ccode' : 'DEU', 'cname' : 'Germany'},
  {'ccode' : 'ITA', 'cname' : 'Italy'},
  {'ccode' : 'IDN', 'cname' : 'Indonesia'},
  {'ccode' : 'UKR', 'cname' : 'Ukraine'},
  {'ccode' : 'USA	', 'cname' : 'United States'},
  {'ccode' : 'YEM', 'cname' : '	Yemen'}
 ]
 const  roles =  [ 'consultant', 'distributor', 'seller', 'salesman', 'shop'];
 const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  error: {
    color: theme.palette.error.main,
    paddingLeft: 10,
    fontSize: 13
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  formControl: {
    width: '100%',
  },
  profit: {
    width: '100%'
  },
  menuItem: {
    textTransform: 'capitalize'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  success: {
    backgroundColor: theme.palette.primary.main,
    margin: 5
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  icon: {
    fontSize: 15,
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  errorMessage: {
    backgroundColor: theme.palette.error.dark,
    '& .MuiSnackbarContent-root' : {
      padding: 'none',
      marginTop: 10
    },
    marginTop: theme.spacing(1),
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  role: {
    textTransform: 'capitalize',
    backgroundColor: theme.palette.white,
    minWidth: 70
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 55,
    // flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  textField: {
    marginTop: theme.spacing(2),
    width: '100%',
    backgroundColor: theme.palette.white
  },
  policy: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  policyCheckbox: {
    marginLeft: '-14px'
  },
  submitButton: {
    margin: theme.spacing(2, 0)
  }
}));

const Add = props => {
  const classes = useStyles();
  const [updated, setUpdated] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [formState, setFormState] = useState({
    isValid: false,
    values: { role: 6},
    touched: {},
    errors: {}
  });
  const authData = useSelector(state => state.authData);
  const dispatcher = useDispatch();
  const[passwordCheck, setPasswordCheck] = useState(true);
  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSubmit = event => {
    setTimeout(() => {
      setRedirect(true);
    }, 2000);
    event.preventDefault();
    if(formState.values.password !== formState.values.repeatPassword) {
        setPasswordCheck(false);
      } else {
      const update = async () => {
      setPasswordCheck(true);
      const response = await createAccount({  dispatcher: dispatcher, params: formState.values });
        setUpdated(true);
      };
      update();
      setRedirect(false)
    }
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <div className={classes.content}>
          <div className={classes.contentBody}>
            <Card style={{margin: 20}}>
              <form
                variant='outlined'
                className={classes.form}
                onSubmit={handleSubmit}
              >
                <Typography
                  className={classes.title}
                  variant="h2"
                >
                  Create new account
                </Typography>
                <Typography
                  color="textSecondary"
                  gutterBottom
                >
                  Use your email to create new account
                </Typography>
                <Grid container spacing={3}>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      className={classes.textField}
                      error={hasError('userName')}
                      fullWidth
                      helperText={
                        hasError('userName') ? formState.errors.userName[0] : null
                      }
                      label="Username"
                      name="userName"
                      onChange={handleChange}
                      type="text"
                      value={formState.values.userName || ''}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      className={classes.textField}
                      error={hasError('email')}
                      fullWidth
                      helperText={
                        hasError('email') ? formState.errors.email[0] : null
                      }
                      label="Email address"
                      name="email"
                      onChange={handleChange}
                      type="text"
                      value={formState.values.email || ''}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      className={classes.textField}
                      error={hasError('password')}
                      fullWidth
                      helperText={
                        hasError('password') ? formState.errors.password[0] : null
                      }
                      label="Password"
                      name="password"
                      onChange={handleChange}
                      type="password"
                      value={formState.values.password || ''}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      className={classes.textField}
                      error={hasError('repeatPassword')}
                      fullWidth
                      helperText={
                        hasError('repeatPassword') ? formState.errors.repeatPassword[0] : null
                      }
                      label="Repeat password"
                      name="repeatPassword"
                      onChange={handleChange}
                      type="password"
                      value={formState.values.repeatPassword || ''}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      className={classes.textField}
                      error={hasError('firstName')}
                      fullWidth
                      helperText={
                        hasError('firstName') ? formState.errors.firstName[0] : null
                      }
                      label="First name"
                      name="firstName"
                      onChange={handleChange}
                      type="text"
                      value={formState.values.firstName || ''}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      className={classes.textField}
                      error={hasError('lastName')}
                      fullWidth
                      helperText={
                        hasError('lastName') ? formState.errors.lastName[0] : null
                      }
                      label="Last name"
                      name="lastName"
                      onChange={handleChange}
                      type="text"
                      value={formState.values.lastName || ''}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                    className={classes.policy}
                  >
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel htmlFor="select-role">Role</InputLabel>
                      <Select
                        className={classes.role}
                        value={ formState.values.role || ''}
                        label = {ENUMS.roleIds[formState.values.role]}
                        onChange={handleChange}
                        inputProps={{
                          name: 'role',
                          id: 'select-role',
                        }}
                      >
                        {!!roles && roles.map(role => {
                          const roleId =ENUMS.roleIds[role];
                          return ( <MenuItem className={classes.menuItem} value={roleId} key={role}>{role}</MenuItem>)
                        })
                        }
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      className={classes.textField}
                      error={hasError('profit')}
                      helperText={
                        hasError('profit') ? formState.errors.profit[0] : null
                      }
                      label="Profit(%)"
                      name="profit"
                      onChange={handleChange}
                      type="profit"
                      value={formState.values.profit || ''}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                    className={classes.policy}
                  >
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel htmlFor="filled-country">Country</InputLabel>
                      <Select
                        className={classes.role}
                        value={formState.values.country || ''}
                        onChange={handleChange}
                        inputProps={{
                          name: 'country',
                          id: 'filled-country',
                        }}
                      >
                      {!!countries && countries.map(country => {
                        return ( <MenuItem className={classes.menuItem} value={country.ccode} key={country.ccode}>{country.cname}</MenuItem>)
                      })
                      }
                      </Select>
                    </FormControl>
                  </Grid>
                  {!passwordCheck &&
                    <span className={classes.error}>Passwords don't match</span>
                  }
                  <Grid
                    item
                    md={6}
                    xs={12}
                    className={classes.policy}
                  >
                    <Checkbox
                      checked={formState.values.policy || false}
                      className={classes.policyCheckbox}
                      color="primary"
                      name="policy"
                      onChange={handleChange}
                    />
                    <Typography
                      className={classes.policyText}
                      color="textSecondary"
                      variant="body1"
                    >
                      I have read the{' '}
                      <Link
                        color="primary"
                        component={RouterLink}
                        to="#"
                        underline="always"
                        variant="h6"
                      >
                        Terms and Conditions
                      </Link>
                    </Typography>
                  </Grid>
                  {hasError('policy') && (
                    <FormHelperText error>
                      {formState.errors.policy[0]}
                    </FormHelperText>
                  )}
                  </Grid>
                  <Button
                    className={classes.submitButton}
                    color="primary"
                    disabled={!formState.isValid}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Submit
                  </Button>
                  {authData.errorMessage &&
                      <SnackbarContent
                      className={classes.errorMessage}
                      message={<span><ErrorIcon className={classes.icon}/>{authData.errorMessage}</span>}
                      >
                      </SnackbarContent> 
                  }
                  {!authData.errorMessage && updated &&
                    <div>
                        <SnackbarContent
                          className={classes.success}
                          message= {<span><CheckCircleIcon className={classes.icon}/>
                            Created succesfully!</span>}
                          autohideduration={1000}
                        >
                          <CheckCircleIcon/>
                        </SnackbarContent>
                        { redirect &&
                          <Redirect to = "/dashboard"></Redirect>
                        }
                    </div>
                  }
                </form>
              </Card>
            </div>
          </div>
      </Grid>
    </div>
  );
};


export default withRouter(Add);
